import { TestBuilderCommentProvider } from "Components/comment-provider/TestBuilderCommentProvider"
import { useTestBuilderCommentLiveUpdates } from "Hooks/useTestBuilderCommentLiveUpdates"
import {
  updateUserActivityAction,
  useUsabilityTestUserActivityLiveUpdates,
} from "Hooks/useUsabilityTestUserActivityLiveUpdates"
import {
  getId,
  getUniqueId,
  hasActiveOrders,
  responseCount,
} from "Redux/reducers/test-builder/selectors/test-form"
import { DeviceFrame, S3Config, UsabilityTestUserActivity } from "Types"
import { HasResponsesWarningModal } from "UsabilityHub/components/UsabilityTestUserActivitiesWidget/HasResponsesWarningModal"
import { SomeoneIsEditingWarningModal } from "UsabilityHub/components/UsabilityTestUserActivitiesWidget/SomeoneIsEditingWarningModal"
import { UserActivitiesWidget } from "UsabilityHub/components/UsabilityTestUserActivitiesWidget/UserActivitiesWidget"
import { DeviceFramesProvider } from "UsabilityHub/contexts"
import { useModal } from "Utilities/modals/use-modal"
import { orderBy } from "lodash"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { S3ConfigProvider } from "./S3ConfigContext"
import { TestFormFieldsContainer } from "./TestFormFieldsContainer"
import { UsabilityTestUserActivityProvider } from "./UsabilityTestUserActivityContext"
import { HasActiveOrdersError } from "./has-active-orders-error"

import "./hitzone-editor-modal.scss"
// Remove these styles after porting components over to ChakraUI or use css modules
import "./screenshot-editor.scss"

type Props = {
  s3Config: S3Config
  deviceFrames: DeviceFrame[]
}

export const TestForm: React.FC<React.PropsWithChildren<Props>> = ({
  s3Config,
  deviceFrames,
}) => {
  const usabilityTestId = useSelector(getId)
  const testUniqueId = useSelector(getUniqueId)
  const testResponseCount = useSelector(responseCount)
  const testHasActiveOrders = useSelector(hasActiveOrders)

  useTestBuilderCommentLiveUpdates(usabilityTestId)

  const showTestHasActiveOrders = !!(testUniqueId && testHasActiveOrders)

  const [acceptedWarning, setAcceptedWarning] = useState(false)
  const showHasResponsesWarning = !!(
    testUniqueId &&
    testResponseCount &&
    !acceptedWarning
  )

  const [userActivities, setUserActivities] = useState<
    UsabilityTestUserActivity[]
  >([])

  const [loadingUserActivities, setLoadingUserActivities] = useState(
    !showTestHasActiveOrders
  )

  const userActivitiesRetrieved = (activities: UsabilityTestUserActivity[]) => {
    setUserActivities(orderBy(activities, ["started_at"], ["desc"]))
    setLoadingUserActivities(false)
  }

  // Subscribe a channel to get real-time user activities

  const startWithReadOnly = showHasResponsesWarning || showTestHasActiveOrders

  const { updateUserActivity: updateUserAcitivityWithStatus } =
    useUsabilityTestUserActivityLiveUpdates(
      usabilityTestId,
      userActivitiesRetrieved,
      startWithReadOnly
    )

  const updateUserActivity: updateUserActivityAction = (status) => {
    setLoadingUserActivities(true)
    updateUserAcitivityWithStatus(status)
  }

  // The control switch of HasResponsesWarningModal
  const {
    open: openHasResponsesWarningModal,
    close: closeHasResponsesWarningModal,
  } = useModal(HasResponsesWarningModal)

  const otherEditors = userActivities.filter(
    (a) => a.session_id !== sessionStorage.getItem("tabId")
  )
  // The control switch of SomeoneIsEditingWarningModal
  const showSomeoneIsEditingWarning = otherEditors.length > 0

  const {
    open: openSomeoneIsEditingWarningModal,
    close: closeSomeoneIsEditingWarningModal,
  } = useModal(SomeoneIsEditingWarningModal)

  // Actions to perform
  // when a user accepts HasResponsesWarningModal or SomeoneIsEditingWarningModal
  const handleAcceptWarning = () => {
    setAcceptedWarning(true)

    updateUserActivity("editing")
    closeHasResponsesWarningModal()
    closeSomeoneIsEditingWarningModal()
  }

  // Show HasActiveOrdersError
  if (testUniqueId && testHasActiveOrders) {
    return <HasActiveOrdersError testUniqueId={testUniqueId} />
  }

  return (
    <UsabilityTestUserActivityProvider
      userActivities={userActivities}
      updateUserActivity={updateUserActivity}
      isNewTest={!usabilityTestId}
      showHasResponsesWarning={showHasResponsesWarning}
      setAcceptedWarning={setAcceptedWarning}
    >
      <TestBuilderCommentProvider>
        <UserActivitiesWidget
          showHasResponsesWarning={showHasResponsesWarning}
          openHasResponsesWarningModal={() =>
            openHasResponsesWarningModal({
              usabilityTestId: testUniqueId!,
              handleAcceptWarning,
            })
          }
          showSomeoneIsEditingWarning={showSomeoneIsEditingWarning}
          openSomeoneIsEditingWarningModal={() =>
            openSomeoneIsEditingWarningModal({
              handleAcceptWarning,
              activities: userActivities,
            })
          }
          showTestHasActiveOrders={showTestHasActiveOrders}
          loadingUserActivities={loadingUserActivities}
        />
        <S3ConfigProvider s3Config={s3Config}>
          <DeviceFramesProvider deviceFrames={deviceFrames}>
            <TestFormFieldsContainer />
          </DeviceFramesProvider>
        </S3ConfigProvider>
      </TestBuilderCommentProvider>
    </UsabilityTestUserActivityProvider>
  )
}
