import { useToast } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { useCallback } from "react"

import { useModal } from "Utilities/modals/use-modal"
import {
  CancelModeratedStudyBookingVariables,
  RescheduleModeratedStudyBookingRequestBody,
  UpdateModeratedStudyBookingVariables,
  useCancelModeratedStudyBooking,
  useMarkModeratedStudyBookingAsComplete,
  useMarkModeratedStudyBookingAsNoShow,
  useMarkModeratedStudyBookingAsPaid,
  useMarkModeratedStudyBookingAsReported,
  useRescheduleModeratedStudyBooking,
  useUpdateModeratedStudyBooking,
} from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { ModeratedStudy } from "~/api/generated/usabilityhubSchemas"

import { interviewBookingCompletedGoogle } from "JavaScripts/analytics/google"
import { Rating } from "../ParticipantRatingForm"
import { CancelBookingModal } from "./CancelBookingModal"
import { RescheduleBookingModal } from "./RescheduleBookingModal"
import { Booking } from "./types"

const useInvalidateBookingsQuery = (moderatedStudyId: string) => {
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()

  return useCallback(() => {
    return queryClient.invalidateQueries(
      queryKeyFn({
        path: "/api/moderated_studies/{moderated_study_id}/bookings",
        operationId: "getModeratedStudyBookings",
        variables: {
          pathParams: {
            moderatedStudyId: moderatedStudyId,
          },
        },
      })
    )
  }, [queryClient, queryKeyFn, moderatedStudyId])
}

type UseBookingActionsProps = {
  moderatedStudyId: ModeratedStudy["id"]
  booking: Booking
  invalidateStudySummaryQuery: () => Promise<void>
  useUpdate?: typeof useUpdateModeratedStudyBooking
  useInvalidateBookings?: typeof useInvalidateBookingsQuery
  hasAvailableBookingSlots: boolean
}

const useBookingActions = ({
  moderatedStudyId,
  booking,
  invalidateStudySummaryQuery,
  useUpdate = useUpdateModeratedStudyBooking,
  useInvalidateBookings = useInvalidateBookingsQuery,
  hasAvailableBookingSlots,
}: UseBookingActionsProps) => {
  const toast = useToast()

  const invalidateBookingsQuery = useInvalidateBookings(moderatedStudyId)

  const pathParams = {
    moderatedStudyId,
    moderatedStudyBookingId: booking.id,
  } as const

  const { mutateAsync } = useUpdate({
    onSuccess: () => {
      void invalidateStudySummaryQuery()
      void invalidateBookingsQuery()
      toast({
        title: "Session updated successfully",
        status: "success",
      })
    },
    onError: (error) => {
      toast({
        title: "Error",
        description:
          "code" in error.payload &&
          error.payload.code === "AuthenticationError"
            ? "There was an error authenticating your Microsoft account. One reason this could happen is if you don\u2019t have Teams enabled on the account."
            : (error.payload.message ??
              "An error occurred while saving the session."),
        status: "error",
      })
    },
  })

  const { mutate: cancelBooking } = useCancelModeratedStudyBooking({
    onSuccess: () => {
      void invalidateStudySummaryQuery()
      toast({
        title: "Session canceled",
        status: "success",
      })
      return invalidateBookingsQuery()
    },
    onError: (error) => {
      toast({
        title: "Error",
        description:
          error.payload?.message ??
          "An error occurred while canceling the session.",
        status: "error",
      })
    },
  })
  const { open: openCancelBookingModal } = useModal(CancelBookingModal)

  const { mutate: rescheduleBooking } = useRescheduleModeratedStudyBooking({
    onSuccess: () => {
      void invalidateStudySummaryQuery()
      toast({
        title: "Session reschedule requested",
        status: "success",
      })
      return invalidateBookingsQuery()
    },
    onError: (error) => {
      toast({
        title: "Error",
        description:
          error.payload?.message ??
          "An error occurred while rescheduling the session.",
        status: "error",
      })
    },
  })
  const { open: openRescheduleBookingModal } = useModal(RescheduleBookingModal)

  const { mutate: markAsComplete } = useMarkModeratedStudyBookingAsComplete({
    onSuccess: () => {
      toast({
        title: "Session marked as completed",
        status: "success",
      })

      interviewBookingCompletedGoogle()

      return invalidateBookingsQuery()
    },
    onError: () => {
      toast({
        title: "Error",
        description:
          "An error occurred while marking the session as completed.",
        status: "error",
      })
    },
  })

  const { mutate: markAsPaid } = useMarkModeratedStudyBookingAsPaid({
    onSuccess: () => {
      toast({
        title: "Session marked as paid",
        status: "success",
      })
      return invalidateBookingsQuery()
    },
    onError: () => {
      toast({
        title: "Error",
        description: "An error occurred while marking the session as paid.",
        status: "error",
      })
    },
  })

  const { mutate: markAsNoShow } = useMarkModeratedStudyBookingAsNoShow({
    onSuccess: () => {
      toast({
        title: "Session marked as no-show",
        status: "success",
      })
      return invalidateBookingsQuery()
    },
    onError: () => {
      toast({
        title: "Error",
        description: "An error occurred while marking the session as no-show.",
        status: "error",
      })
    },
  })

  const { mutate: markAsReported } = useMarkModeratedStudyBookingAsReported({
    onSuccess: () => {
      toast({
        title: "Session reported",
        status: "success",
      })
      return invalidateBookingsQuery()
    },
    onError: () => {
      toast({
        title: "Error",
        description: "An error occurred while reporting the session.",
        status: "error",
      })
    },
  })

  return {
    mutateAsync: async (
      variables: Omit<UpdateModeratedStudyBookingVariables, "pathParams">
    ) => {
      try {
        return await mutateAsync({ pathParams, ...variables })
        // We just need to catch this so it doesn't break the page (since it's async);
        // it's handled in the onError
      } catch (error) {}
    },
    openCancelBookingModal: () => {
      openCancelBookingModal({
        cancelBooking: (
          variables: Omit<CancelModeratedStudyBookingVariables, "pathParams">
        ) => cancelBooking({ pathParams, ...variables }),
        booking,
      })
    },
    openRescheduleBookingModal: () => {
      openRescheduleBookingModal({
        rescheduleBooking: (body: RescheduleModeratedStudyBookingRequestBody) =>
          rescheduleBooking({ pathParams, body }),
        booking,
        hasAvailableBookingSlots,
        moderatedStudyId,
      })
    },
    markAsComplete: (options: {
      mark_as_paid: boolean
      rating?: Rating
      comment?: string
    }) =>
      markAsComplete({
        pathParams,
        body: {
          ...options,
          rating: options.rating ?? null,
          comment: options.comment ?? null,
        },
      }),
    markAsPaid: () => markAsPaid({ pathParams }),
    markAsNoShow: () => markAsNoShow({ pathParams }),
    markAsReported: (options: { reportReason: string; reportLink: string }) =>
      markAsReported({
        pathParams,
        body: {
          reason: options.reportReason,
          link: options.reportLink,
        },
      }),
  }
}

export default useBookingActions
