import { zip } from "lodash"

import { UsabilityTest } from "Types"

// The following kinds of changes matter to our estimated duration:
// - Number of sections
// - Section instructions length (`text`)
// - Number of questions
// - Question text
// - Question type
// - Question options
// - Number of section screenshots
// - For card sort sections, number of cards and categories
// - For tree test sections, max depth and average arity of nodes
//
// Comparison of questions by identity is a bit over-eager since it catches
// changes to things that don't affect durations (eg. 'mandatory'), but we do
// it this way for simplicity and efficiency.

export const hasUsabilityTestLengthChanged = (
  values: Readonly<UsabilityTest>,
  previousValues: Readonly<UsabilityTest>
) => {
  if (values.screener !== previousValues.screener) {
    return true
  }

  if (values.sections.length !== previousValues.sections.length) {
    return true
  }
  return zip(previousValues.sections, values.sections).some(
    ([prev, next]) =>
      prev!.questions !== next!.questions ||
      prev!.section_screenshots.length !== next!.section_screenshots.length ||
      prev!.card_sort_attributes?.card_sort_cards_attributes.length !==
        next!.card_sort_attributes?.card_sort_cards_attributes.length ||
      prev!.card_sort_attributes?.card_sort_categories_attributes.length !==
        next!.card_sort_attributes?.card_sort_categories_attributes.length ||
      prev!.card_sort_attributes?.number_of_cards_shown !==
        next!.card_sort_attributes?.number_of_cards_shown ||
      prev!.tree_test_attributes?.nodes !== next!.tree_test_attributes?.nodes ||
      prev!.recording_attributes?.microphone !==
        next!.recording_attributes?.microphone ||
      prev!.recording_attributes?.screen !==
        next!.recording_attributes?.screen ||
      prev!.recording_attributes?.camera !==
        next!.recording_attributes?.camera ||
      prev!.external_study_attributes?.estimated_duration_in_minutes !==
        next!.external_study_attributes?.estimated_duration_in_minutes
  )
}
