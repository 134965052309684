import {
  Badge,
  Box,
  Center,
  type CenterProps,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { ClockIcon } from "@heroicons/react/solid"
import { Checkmark, Cross, NotApplicable } from "Components/checkmark/Checkmark"
import { ClickMapOld } from "Components/click-map/click-map-old"
import { getFirstClickSectionClicks } from "Components/individual-response/individual-section-result/SectionTaskIndividualResults/FirstClickSectionIndividualResults/FirstClickSectionIndividualResults"
import { clickClippingRectangle } from "Components/individual-response/individual-section-result/SectionTaskIndividualResults/FirstClickSectionIndividualResults/click-summary-old"
import { getTreeTestResponseStats } from "Components/individual-response/individual-section-result/SectionTaskIndividualResults/TreeTestSectionIndividualResults"
import { Badge as TreeTestBadge } from "Components/individual-response/individual-section-result/SectionTaskIndividualResults/TreeTestSectionIndividualResults/Badge"
import { Path } from "Components/individual-response/individual-section-result/SectionTaskIndividualResults/TreeTestSectionIndividualResults/Path"
import { useFilteredClicks } from "Components/test-results/hooks/use-filtered-clicks"
import { ClippedThumbnail } from "Components/test-results/progress-box/thumbnail"
import { useTaskGoalNodeId } from "Components/test-results/section-results/SectionResultsCards/PrototypeSectionResultsCard/hooks/use-goal-node-id"
import { getIndividualFigmaTaskPathFromTask } from "Components/test-results/utils/task-paths/get-individual-paths/get-individual-paths"
import { PreciseDuration } from "Components/time/precise-duration"
import type { ResponseSectionRecording } from "JavaScripts/types/recording"
import type { State } from "Redux/app-store"
import { getScreenshotWithId } from "Redux/reducers/screenshots/selectors"
import {
  getFigmaTaskForResponseSection,
  getResponseSectionCardSortsForResponseSection,
} from "Redux/reducers/test-results/selectors"
import { AlertTriangleSolidIcon } from "Shared/icons/untitled-ui/AlertTriangleSolidIcon"
import { CursorClick01SolidIcon } from "Shared/icons/untitled-ui/CursorClick01SolidIcon"
import {
  type ImageScreenshot,
  type ResponseSection,
  type Screenshot,
  type UsabilityTestSection,
  UsabilityTestSectionType,
} from "Types"
import { ContactSupportButton } from "UsabilityHub/components/ContactSupportButton"
import { useSectionContext } from "UsabilityHub/contexts"
import { formatPercentage01 } from "Utilities/number"
import React, {
  useEffect,
  useRef,
  useState,
  type PropsWithChildren,
} from "react"
import { useSelector } from "react-redux"
import { useNavigationTestResultsStepContext } from "../../NavigationTestResultsStepContext"
import { getCardSortResponseStats } from "../../card-sort-result"
import { GoalScreenBadge } from "../PrototypeSectionResultsCard/PathsSummaries/PathStats/GoalScreenBadge"

type Props = {
  responseSection: ResponseSection
  status: ResponseSectionRecording["status"]
  hasRecordingError: boolean
}

export const RecordingContent: React.FC<Props> = ({
  responseSection,
  status,
  hasRecordingError,
}) => {
  const { section } = useSectionContext()

  if (hasRecordingError) {
    return <RecordingErrorView />
  }

  const content = (sectionType: UsabilityTestSectionType): React.ReactNode => {
    switch (sectionType) {
      case UsabilityTestSectionType.CardSort:
        return (
          <CardSortContent
            usabilityTestSection={section}
            responseSection={responseSection}
          />
        )
      case UsabilityTestSectionType.FirstClickTest:
        return (
          <FirstClickContent
            usabilityTestSection={section}
            responseSection={responseSection}
          />
        )
      case UsabilityTestSectionType.NavigationTest:
        return <NavigationContent responseSection={responseSection} />
      case UsabilityTestSectionType.PrototypeTask:
        return <PrototypeContent responseSection={responseSection} />
      case UsabilityTestSectionType.TreeTest:
        return (
          <TreeTestContent
            usabilityTestSection={section}
            responseSection={responseSection}
          />
        )
    }
  }

  return (
    <>
      {status === "failed" ? (
        <RecordingErrorView />
      ) : (
        <Stack spacing={2} p={3}>
          {status === "processed" ? (
            content(section.type)
          ) : (
            <RecordingProcessingView />
          )}
        </Stack>
      )}
    </>
  )
}

export const RecordingErrorView: React.FC<
  CenterProps & { text?: string; mediumFonts?: boolean }
> = ({ text, mediumFonts, ...props }) => (
  <Center
    flexDirection="column"
    gap={2}
    p={3}
    h="full"
    bgColor="ds.background.warning.subtle.resting"
    {...props}
  >
    <Flex gap={2}>
      <Icon as={AlertTriangleSolidIcon} boxSize={6} color="ds.icon.warning" />
      <Flex direction="column" color="ds.text.default">
        <Text
          textStyle={mediumFonts ? "ds.heading.primary" : "ds.interface.small"}
        >
          Error loading media
        </Text>
        <Text
          pt={1}
          pb={2}
          textStyle={
            mediumFonts ? "ds.paragraph.primary" : "ds.paragraph.secondary"
          }
        >
          {text || "Try refreshing the page."}
        </Text>
        <ContactSupportButton
          textTransform="uppercase"
          textStyle="ds.interface.xsmall"
        />
      </Flex>
    </Flex>
  </Center>
)

export const RecordingProcessingView: React.FC<CenterProps> = (props) => (
  <Center flexDirection="column" gap={2} h="full" textAlign="center" {...props}>
    <Text textStyle="ds.interface.medium" color="ds.text.subtle">
      Processing upload
    </Text>
    <Text textStyle="ds.paragraph.secondary" color="ds.text.subtlest">
      Check back later, your recording will be available soon
    </Text>
  </Center>
)

type ContentProps = {
  responseSection: ResponseSection
  usabilityTestSection: UsabilityTestSection
}

const CardSortContent: React.FC<ContentProps> = ({
  responseSection,
  usabilityTestSection,
}) => {
  const sortResults = useSelector((state: State) =>
    getResponseSectionCardSortsForResponseSection(state, responseSection.id)
  )
  const cardSort = usabilityTestSection.card_sort_attributes!
  const cards = cardSort.card_sort_cards_attributes

  const { noOfCards, noOfUnsortedCards } = getCardSortResponseStats(
    sortResults,
    cards
  )

  const noOfSortedCards = noOfCards - noOfUnsortedCards
  const taskDuration = responseSection.task_duration_ms
    ? responseSection.task_duration_ms
    : 0

  return (
    <>
      <RowContentContainer>
        {noOfSortedCards} sorted, {noOfUnsortedCards} unsorted
      </RowContentContainer>
      <RowContentContainer icon={ClockIcon}>
        Ave time per card{" "}
        <PreciseDuration ms={taskDuration / noOfSortedCards} />
      </RowContentContainer>
      <TaskDuration taskDuration={taskDuration} />
    </>
  )
}

const FirstClickContent: React.FC<ContentProps> = ({
  responseSection,
  usabilityTestSection,
}) => {
  const clicks = getFirstClickSectionClicks({
    responseSection,
    usabilityTestSection,
  })

  if (clicks.length === 0) return

  // We used to historically allow multiple clicks (there was an option so you could set it to “Allow at most X clicks”, defaulted to 1 and maybe maxed out at 5 or something), but now it should have one click.
  const click = clicks[0]
  const sectionScreenshot = usabilityTestSection.section_screenshots.find(
    (ss) => ss.id === click.usability_test_section_screenshot_id
  )
  const screenshot = useSelector<State, Screenshot>((state) =>
    getScreenshotWithId(state, sectionScreenshot!.screenshot_id)
  ) as ImageScreenshot

  const taskDuration = responseSection.task_duration_ms
    ? responseSection.task_duration_ms
    : 0

  return (
    <>
      <RowContentContainer>Click 1</RowContentContainer>
      <ClippedThumbnail
        screenshot={screenshot}
        clippingRectangle={clickClippingRectangle(click, screenshot)}
      >
        <ClickMapOld
          clicks={[{ id: click.id, x: 0.5, y: 0.5, hit: click.hit }]}
        />
      </ClippedThumbnail>
      <TaskDuration taskDuration={taskDuration} />
    </>
  )
}

const NavigationContent: React.FC<
  Omit<ContentProps, "usabilityTestSection">
> = ({ responseSection }) => {
  const { stepNumber, sectionScreenshot } =
    useNavigationTestResultsStepContext()
  const clicks = useFilteredClicks()
  const click = clicks.find(
    (c) =>
      c.usability_test_section_screenshot_id === sectionScreenshot.id &&
      c.response_id === responseSection.response_id
  )

  const content = click
    ? click.hit
      ? "navigated successfully"
      : "navigated unsuccessfully"
    : "never reached"

  const icon = click ? click.hit ? <Checkmark /> : <Cross /> : <NotApplicable />

  return (
    <>
      <RowContentContainer>
        <RowBadge color={click?.hit ? "green" : undefined} content={content} />
      </RowContentContainer>
      <RowContentContainer>
        {icon} Step {stepNumber}
      </RowContentContainer>
      <TaskDuration taskDuration={responseSection.task_duration_ms || 0} />
    </>
  )
}

const PrototypeContent: React.FC<
  Omit<ContentProps, "usabilityTestSection">
> = ({ responseSection }) => {
  const figmaTask = useSelector(
    getFigmaTaskForResponseSection(responseSection?.id || null)
  )
  const goalNodeId = useTaskGoalNodeId()

  if (figmaTask === null) {
    throw new Error("No figma task found")
  }

  const { meta } = getIndividualFigmaTaskPathFromTask(figmaTask, goalNodeId)

  const { clicks, duration, misclickRate, goalScreenHasBeenReached } = meta

  return (
    <>
      <Box>
        <GoalScreenBadge goalScreenHasBeenReached={goalScreenHasBeenReached} />
      </Box>
      <RowContentContainer
        icon={CursorClick01SolidIcon}
        tooltip="Total number of clicks by this participant"
      >
        {clicks.length} Clicks ({formatPercentage01(misclickRate)} misclicks)
      </RowContentContainer>
      <TaskDuration taskDuration={duration} />
    </>
  )
}

const TreeTestContent: React.FC<ContentProps> = ({
  responseSection,
  usabilityTestSection,
}) => {
  const { path, result, direct } = getTreeTestResponseStats({
    responseSection,
    usabilityTestSection,
  })

  const pathRef = useRef<HTMLDivElement>(null)
  const [isTruncated, setIsTruncated] = useState(false)
  useEffect(() => {
    const element = pathRef.current
    if (element) {
      setIsTruncated(element.scrollHeight > element.clientHeight)
    }
  }, [])

  const pathContent = <Path path={path} noOfLines={1} containerRef={pathRef} />

  const taskDuration = responseSection.task_duration_ms
    ? responseSection.task_duration_ms
    : 0

  return (
    <>
      <Box>
        <TreeTestBadge
          result={result}
          directness={direct ? "direct" : "indirect"}
        />
      </Box>
      {isTruncated ? (
        <Tooltip
          label={<Path path={path} />}
          rounded="md"
          bgColor="ds.background.input.resting"
          border="2px"
          boxShadow="base"
          shouldWrapChildren
        >
          {pathContent}
        </Tooltip>
      ) : (
        pathContent
      )}
      <TaskDuration taskDuration={taskDuration} />
    </>
  )
}

type TaskDurationProps = {
  taskDuration: number
}

const TaskDuration: React.FC<TaskDurationProps> = ({ taskDuration }) => (
  <RowContentContainer
    icon={ClockIcon}
    tooltip="Total time spent by this participant"
  >
    Task duration <PreciseDuration ms={taskDuration} />
  </RowContentContainer>
)

type RowContentContainerProps = {
  tooltip?: string
  icon?: React.ElementType
}

const RowContentContainer: React.FC<
  PropsWithChildren<RowContentContainerProps>
> = ({ tooltip, icon, children }) => {
  const content = (
    <HStack
      spacing={1}
      color="text.secondary"
      fontSize="sm"
      alignItems="center"
    >
      {icon && <Icon as={icon} boxSize={4} />}
      <Text textStyle="label" color="text.secondary" fontWeight="medium">
        {children}
      </Text>
    </HStack>
  )

  if (!tooltip) return content

  return (
    <Tooltip hasArrow placement="top" label={tooltip}>
      {content}
    </Tooltip>
  )
}

type RowBadgeProps = {
  content: string
  color?: string
}

const RowBadge: React.FC<RowBadgeProps> = ({ content, color }) => (
  <Badge
    variant="solid"
    colorScheme={color}
    whiteSpace="normal"
    fontSize="11px"
    fontWeight="semibold"
  >
    {content}
  </Badge>
)
