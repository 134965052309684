import {
  Box,
  ComponentWithAs,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { ParticipantIcon } from "Components/ParticipantIcon"
import { ClickMapOld } from "Components/click-map/click-map-old"
import { getFirstClickSectionClicks } from "Components/individual-response/individual-section-result/SectionTaskIndividualResults/FirstClickSectionIndividualResults/FirstClickSectionIndividualResults"
import { clickClippingRectangle } from "Components/individual-response/individual-section-result/SectionTaskIndividualResults/FirstClickSectionIndividualResults/click-summary-old"
import { getTreeTestResponseStats } from "Components/individual-response/individual-section-result/SectionTaskIndividualResults/TreeTestSectionIndividualResults"
import { Badge as TreeTestBadge } from "Components/individual-response/individual-section-result/SectionTaskIndividualResults/TreeTestSectionIndividualResults/Badge"
import {
  RecordingPlayer,
  getShareUrl,
} from "Components/test-recording/RecordingPlayer"
import { useFilteredClicks } from "Components/test-results/hooks/use-filtered-clicks"
import { useIndividualResponseFilter } from "Components/test-results/hooks/use-individual-response-filter"
import { ClippedThumbnail } from "Components/test-results/progress-box/thumbnail"
import { getIndividualFigmaTaskPathFromTask } from "Components/test-results/utils/task-paths/get-individual-paths/get-individual-paths"
import { PreciseDuration } from "Components/time/precise-duration"
import { Alert, Badge, Heading, IconButton } from "DesignSystem/components"
import { useFeatureFlagLoading } from "Hooks/use-feature-flag"
import { ResponseSectionRecording } from "JavaScripts/types/recording"
import { State } from "Redux/app-store"
import { getScreenshotWithId } from "Redux/reducers/screenshots/selectors"
import {
  getFigmaTaskForResponseSection,
  getResponse,
  getResponseSectionCardSortsForResponseSection,
} from "Redux/reducers/test-results/selectors"
import { UserResponseIcon } from "Shared/icons/UserResponseIcon"
import { AlertTriangleSolidIcon } from "Shared/icons/untitled-ui/AlertTriangleSolidIcon"
import { CheckCircleSolidIcon } from "Shared/icons/untitled-ui/CheckCircleSolidIcon"
import { ClockSolidIcon } from "Shared/icons/untitled-ui/ClockSolidIcon"
import { CursorClick01SolidIcon } from "Shared/icons/untitled-ui/CursorClick01SolidIcon"
import { DotsVerticalIcon } from "Shared/icons/untitled-ui/DotsVerticalIcon"
import { Download01OutlineIcon } from "Shared/icons/untitled-ui/Download01OutlineIcon"
import { Maximize01OutlineIcon } from "Shared/icons/untitled-ui/Maximize01OutlineIcon"
import { Minimize01OutlineIcon } from "Shared/icons/untitled-ui/Minimize01OutlineIcon"
import { Share07OutlineIcon } from "Shared/icons/untitled-ui/Share07OutlineIcon"
import { SlashCircle01SolidIcon } from "Shared/icons/untitled-ui/SlashCircle01SolidIcon"
import { XCircleSolidIcon } from "Shared/icons/untitled-ui/XCircleSolidIcon"
import { ImageScreenshot, Screenshot, UsabilityTestSectionType } from "Types"
import { ContactSupportButton } from "UsabilityHub/components/ContactSupportButton"
import { useSectionContext } from "UsabilityHub/contexts"
import { useFlatLocations } from "UsabilityHub/hooks/useFlatLocations"
import { getDateString } from "Utilities/date-formats"
import { formatPercentage01 } from "Utilities/number"
import { formatPreciseDuration } from "Utilities/time"
import { motion } from "framer-motion"
import React, { PropsWithChildren, useEffect, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { useSelector } from "react-redux"
import { useNavigationTestResultsStepContext } from "../../NavigationTestResultsStepContext"
import { getCardSortResponseStats } from "../../card-sort-result"
import { GoalScreenBadge } from "../PrototypeSectionResultsCard/PathsSummaries/PathStats/GoalScreenBadge"
import { useTaskGoalNodeId } from "../PrototypeSectionResultsCard/hooks/use-goal-node-id"
import { RecordingContent } from "./RecordingContent"
import { useSectionRecordings } from "./useSectionRecordings"

export const RecordingsTab: React.FC = () => {
  const recordings = useSectionRecordings()

  const { setResponseId } = useIndividualResponseFilter()

  const [recordingId, setRecordingId] = useState<
    ResponseSectionRecording["id"] | undefined
  >(recordings[0]?.id ?? undefined)

  const recording = recordingId
    ? recordings.find((rec) => rec.id === recordingId)
    : undefined

  const response = useSelector((state: State) =>
    recording?.responseSection
      ? getResponse(state, recording?.responseSection?.response_id)
      : undefined
  )

  const responseLocation = response?.response_demographic_profile?.location
  const allLocations = useFlatLocations("usability_tests")
  const locationData =
    responseLocation && allLocations[responseLocation.type][responseLocation.id]

  const [hasRecordingError, setHasRecordingError] = useState(false)
  const { isOpen: isExpanded, onToggle } = useDisclosure()

  const toast = useToast()

  const onShare = async (responseId: number, responseSectionId: number) => {
    const url = getShareUrl(responseId, responseSectionId)
    await navigator.clipboard.writeText(url.toString())

    toast({
      title: "Link copied to clipboard",
      status: "success",
    })
  }

  useEffect(() => {
    setHasRecordingError(false)
  }, [recordingId])

  const { loading, enabled } = useFeatureFlagLoading(
    "new_recordings_results_ui"
  )

  if (recordings.length === 0) {
    if (enabled) {
      return (
        <Grid
          placeContent="center"
          minH="25rem"
          background="ds.background.neutral.resting"
          rounded={12}
        >
          <Text textStyle="ds.heading.primary">No recordings yet</Text>
        </Grid>
      )
    } else {
      return <Alert status="info" description="No recording data available" />
    }
  }

  if (loading) {
    return (
      <Box display="grid" placeItems={"center"} minH="25rem">
        <Spinner size="xl" />
      </Box>
    )
  }

  if (!enabled) {
    // Old grid view
    return (
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        {recordings.map((recording) => (
          // When a signle recording container has errors, we'll ignore it and continue rendering the rest of the recordings.
          <ErrorBoundary key={`error-${recording.id}`} fallback={null}>
            <GridItem boxShadow="base" rounded="md" key={recording.id}>
              <RecordingContainer recording={recording} />
            </GridItem>
          </ErrorBoundary>
        ))}
      </Grid>
    )
  }

  // New hotness
  return (
    <Box
      as={motion.div}
      layout="size"
      layoutId="container"
      initial={false}
      display="grid"
      gridTemplate={
        isExpanded
          ? '"preview" auto "list" calc(12.5rem + 1px) / 1fr'
          : '"preview list" auto / 1fr 22.5rem'
      }
      backgroundColor="ds.background.neutral.resting"
      overflow="hidden"
      style={{
        borderRadius: 12, // specified in `style` so id doesn't warp during animation
      }}
    >
      {recording && (
        <Stack
          as={motion.div}
          key={recordingId}
          layout="size"
          layoutId="preview"
          initial={false}
          gridArea="preview"
          gap={0}
        >
          {hasRecordingError ? (
            <Stack
              alignItems="center"
              justify="center"
              background="ds.background.warning.subtle.resting"
              gap={4}
              minH="23rem"
            >
              <AlertTriangleSolidIcon color="ds.icon.warning" boxSize={8} />
              <Stack align="center" gap={2}>
                <Heading as="h3" textStyle="ds.heading.primary">
                  Media couldn’t load
                </Heading>
                <Text textStyle="ds.paragraph.primary">
                  Please refresh the page to try again.
                </Text>
              </Stack>
              <ContactSupportButton textStyle="ds.interface.medium" />
            </Stack>
          ) : recording.status === "processed" ? (
            <Box background="black" pos="relative">
              <RecordingPlayer
                height="100%"
                recording={recording}
                hideParticipantButton
                hideShareButton
                roundedCorners={false}
                setHasRecordingError={setHasRecordingError}
              />
            </Box>
          ) : (
            <Stack
              alignItems="center"
              justify="center"
              background="ds.background.neutral.bold.resting"
              gap={4}
              minH="23rem"
            >
              <Spinner boxSize={8} color="ds.icon.accent.teal" />
              <Stack align="center" gap={2} color="ds.text.inverse">
                <Heading as="h3" textStyle="ds.heading.primary">
                  Processing upload…
                </Heading>
                <Text textStyle="ds.paragraph.primary">
                  Your recording will be available shortly. Please check back
                  later.
                </Text>
              </Stack>
            </Stack>
          )}
          <HStack align="start" justify="space-between" p={3} gap={4}>
            <RecordingDetails recording={recording} />
            <HStack gap={2} alignSelf="stretch" maxH="50px">
              <IconButton
                variant="secondary"
                size="compact"
                icon={
                  isExpanded ? (
                    <Minimize01OutlineIcon />
                  ) : (
                    <Maximize01OutlineIcon />
                  )
                }
                onClick={onToggle}
                aria-label="Toggle recording content"
              />
              <IconButton
                right={0}
                aria-label="View participant"
                icon={<UserResponseIcon />}
                variant="secondary"
                size="compact"
                onClick={() =>
                  setResponseId(recording.responseSection.response_id)
                }
              />
              {recording.url && (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="More"
                    icon={<DotsVerticalIcon />}
                    variant="secondary"
                    size="compact"
                  />
                  <Portal>
                    <MenuList py={2}>
                      <MenuItem
                        as="a"
                        href={recording.url}
                        download
                        icon={<Download01OutlineIcon />}
                      >
                        Download
                      </MenuItem>

                      <MenuItem
                        icon={<Share07OutlineIcon />}
                        onClick={() =>
                          onShare(
                            recording.responseSection.response_id,
                            recording.responseSection.id
                          )
                        }
                      >
                        Share
                      </MenuItem>
                    </MenuList>
                  </Portal>
                </Menu>
              )}
            </HStack>
          </HStack>
        </Stack>
      )}
      <Stack
        gridArea="list"
        overflowY="auto"
        alignSelf={isExpanded ? "start" : "stretch"}
        borderLeft={isExpanded ? "none" : "1px solid"}
        borderTop={isExpanded ? "1px solid" : "none"}
        borderColor="ds.border.default"
        gap={0}
        px={2}
        py={2}
      >
        {recordings.map((row) => (
          <HStack
            key={row.id}
            align="center"
            px={4}
            py={1}
            borderLeft="4px solid"
            borderColor={
              row.id === recordingId ? "ds.border.selected" : "transparent"
            }
            backgroundColor={
              row.id === recordingId
                ? "ds.background.neutral.resting"
                : "ds.background.neutral.subtle.resting"
            }
            onClick={() => setRecordingId(row.id)}
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            roundedRight={8}
            _hover={{
              background:
                row.id === recordingId
                  ? "ds.background.neutral.resting"
                  : "ds.background.neutral.hovered",
              cursor: "pointer",
            }}
          >
            <HStack gap={0} align="center">
              <Box mb={-2}>
                <ParticipantIcon country={locationData?.countryCode ?? null} />
              </Box>
            </HStack>
            <Text as="div" textStyle="ds.paragraph.secondary" overflow="hidden">
              {getDateString(response?.submitted_at)}
            </Text>
            <Text
              as="div"
              textStyle="ds.paragraph.secondary"
              color="ds.text.subtle"
              overflow="hidden"
              flex={1}
            >
              {formatPreciseDuration(row.duration * 1000)}
            </Text>
          </HStack>
        ))}
      </Stack>
    </Box>
  )
}

const RecordingContainer: React.FC<{ recording: ResponseSectionRecording }> = ({
  recording,
}) => {
  const [hasRecordingError, setHasRecordingError] = React.useState(false)

  return (
    <Stack gap={0} h="full">
      <RecordingPlayer
        recording={recording}
        setHasRecordingError={setHasRecordingError}
      />
      <RecordingContent
        responseSection={recording.responseSection}
        status={recording.status}
        hasRecordingError={hasRecordingError}
      />
    </Stack>
  )
}

type RecordingDetailsProps = {
  recording: ResponseSectionRecording
}

const RecordingDetails: React.FC<RecordingDetailsProps> = ({ recording }) => {
  const { section } = useSectionContext()

  const Components: {
    [key in UsabilityTestSectionType]?: React.FC<RecordingDetailsProps>
  } = {
    [UsabilityTestSectionType.CardSort]: CardSortRecordingDetails,
    [UsabilityTestSectionType.FirstClickTest]: FirstClickRecordingDetails,
    [UsabilityTestSectionType.NavigationTest]: NavigationRecordingDetails,
    [UsabilityTestSectionType.PrototypeTask]: PrototypeRecordingDetails,
    [UsabilityTestSectionType.TreeTest]: TreeTestRecordingDetails,
  } as const

  const Component = Components[section.type]

  return (
    <HStack columnGap={4} rowGap={2} py="2px" align="center" wrap="wrap">
      {Component && <Component recording={recording} />}
    </HStack>
  )
}

const CardSortRecordingDetails: React.FC<RecordingDetailsProps> = ({
  recording,
}) => {
  const { section } = useSectionContext()
  const { responseSection } = recording
  const sortResults = useSelector((state: State) =>
    getResponseSectionCardSortsForResponseSection(state, responseSection.id)
  )
  const cardSort = section.card_sort_attributes!
  const cards = cardSort.card_sort_cards_attributes

  const { noOfCards, noOfUnsortedCards } = getCardSortResponseStats(
    sortResults,
    cards
  )

  const noOfSortedCards = noOfCards - noOfUnsortedCards
  const taskDuration = responseSection.task_duration_ms
    ? responseSection.task_duration_ms
    : 0

  return (
    <>
      <RowContentContainer>
        {noOfSortedCards} sorted, {noOfUnsortedCards} unsorted
      </RowContentContainer>
      <TaskDuration taskDuration={taskDuration} />
      <RowContentContainer icon={ClockSolidIcon}>
        Ave time per card{" "}
        <PreciseDuration ms={taskDuration / noOfSortedCards} />
      </RowContentContainer>
    </>
  )
}

const FirstClickRecordingDetails: React.FC<RecordingDetailsProps> = ({
  recording,
}) => {
  const { responseSection } = recording
  const { section } = useSectionContext()

  if (!section) return null

  const clicks = getFirstClickSectionClicks({
    responseSection,
    usabilityTestSection: section,
  })

  if (clicks.length === 0) return

  // We used to historically allow multiple clicks (there was an option so you could set it to “Allow at most X clicks”, defaulted to 1 and maybe maxed out at 5 or something), but now it should have one click.
  const click = clicks[0]
  const sectionScreenshot = section.section_screenshots.find(
    (ss) => ss.id === click.usability_test_section_screenshot_id
  )
  const screenshot = useSelector<State, Screenshot>((state) =>
    getScreenshotWithId(state, sectionScreenshot!.screenshot_id)
  ) as ImageScreenshot

  const taskDuration = responseSection.task_duration_ms
    ? responseSection.task_duration_ms
    : 0

  return (
    <>
      <Box
        sx={{
          "> div": {
            padding: 0,
            width: "auto",
            height: "auto",
            minWidth: "0",
            maxWidth: "none",
            rounded: 4,
            overflow: "hidden",
          },
        }}
      >
        <ClippedThumbnail
          screenshot={screenshot}
          clippingRectangle={clickClippingRectangle(click, screenshot)}
        >
          <ClickMapOld
            clicks={[{ id: click.id, x: 0.5, y: 0.5, hit: click.hit }]}
          />
        </ClippedThumbnail>
      </Box>
      <Text textStyle="ds.heading.secondary" color="ds.text.default">
        Click 1
      </Text>
      <TaskDuration taskDuration={taskDuration} />
    </>
  )
}

const NavigationRecordingDetails: React.FC<RecordingDetailsProps> = ({
  recording,
}) => {
  const { responseSection } = recording
  const { stepNumber, sectionScreenshot } =
    useNavigationTestResultsStepContext()
  const clicks = useFilteredClicks()
  const click = clicks.find(
    (c) =>
      c.usability_test_section_screenshot_id === sectionScreenshot.id &&
      c.response_id === responseSection.response_id
  )

  const content = click
    ? click.hit
      ? "navigated successfully"
      : "navigated unsuccessfully"
    : "never reached"

  const icon = click
    ? click.hit
      ? CheckCircleSolidIcon
      : XCircleSolidIcon
    : SlashCircle01SolidIcon

  return (
    <>
      <RowContentContainer>
        <Badge
          variant="subtle"
          colorScheme={click?.hit ? "success" : undefined}
          content={content}
          label={
            click
              ? click.hit
                ? "navigated successfully"
                : "navigated unsuccessfully"
              : "never reached"
          }
        />
      </RowContentContainer>
      <RowContentContainer icon={icon}>Step {stepNumber}</RowContentContainer>
      <TaskDuration taskDuration={responseSection.task_duration_ms || 0} />
    </>
  )
}

const PrototypeRecordingDetails: React.FC<RecordingDetailsProps> = ({
  recording,
}) => {
  const { responseSection } = recording
  const figmaTask = useSelector(
    getFigmaTaskForResponseSection(responseSection?.id || null)
  )
  const goalNodeId = useTaskGoalNodeId()

  if (figmaTask === null) {
    throw new Error("No figma task found")
  }

  const { meta } = getIndividualFigmaTaskPathFromTask(figmaTask, goalNodeId)

  const { clicks, duration, misclickRate, goalScreenHasBeenReached } = meta

  return (
    <>
      <GoalScreenBadge goalScreenHasBeenReached={goalScreenHasBeenReached} />
      <RowContentContainer
        icon={CursorClick01SolidIcon}
        tooltip="Total number of clicks by this participant"
      >
        {clicks.length} Clicks ({formatPercentage01(misclickRate)} misclicks)
      </RowContentContainer>
      <TaskDuration taskDuration={duration || 0} />
    </>
  )
}

const TreeTestRecordingDetails: React.FC<RecordingDetailsProps> = ({
  recording,
}) => {
  const { responseSection } = recording
  const { section } = useSectionContext()
  const { result, direct } = getTreeTestResponseStats({
    responseSection,
    usabilityTestSection: section,
  })

  const taskDuration = responseSection.task_duration_ms
    ? responseSection.task_duration_ms
    : 0

  return (
    <>
      <Box>
        <TreeTestBadge
          result={result}
          directness={direct ? "direct" : "indirect"}
        />
      </Box>
      <TaskDuration taskDuration={taskDuration} />
    </>
  )
}

type RowContentContainerProps = {
  tooltip?: string
  icon?: ComponentWithAs<"svg", IconProps>
}

const RowContentContainer: React.FC<
  PropsWithChildren<RowContentContainerProps>
> = ({ tooltip, icon, children }) => {
  const content = (
    <HStack spacing={1} alignItems="center">
      {icon && <Icon as={icon} boxSize={4} color="ds.icon.subtle" />}
      <Text
        textStyle="ds.paragraph.secondary"
        color="ds.text.default"
        fontWeight="medium"
      >
        {children}
      </Text>
    </HStack>
  )

  if (!tooltip) return content

  return (
    <Tooltip hasArrow placement="top" label={tooltip}>
      {content}
    </Tooltip>
  )
}

type TaskDurationProps = {
  taskDuration: number
}

const TaskDuration: React.FC<TaskDurationProps> = ({ taskDuration }) => (
  <RowContentContainer
    icon={ClockSolidIcon}
    tooltip="Total time spent by this participant"
  >
    Task duration <PreciseDuration ms={taskDuration} />
  </RowContentContainer>
)
