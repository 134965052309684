import { Flex, Spinner, useToast } from "@chakra-ui/react"
import { Dispatch } from "Redux/app-store"
import { setScreenshots } from "Redux/reducers/screenshots/action-creators"
import { getInitializeScreenshots } from "Redux/reducers/screenshots/helper"
import { initializeTestResults } from "Redux/reducers/test-results/action-creators"
import { PresentRawTestResultsState } from "Redux/reducers/test-results/reducer"
import { initUsabilityTests } from "Redux/reducers/usability-tests/action-creators"
import { isAxiosError, isNotFoundError } from "Services/axios"
import { DeviceFrame, Language, Screenshot, ShallowUsabilityTest } from "Types"
import { DeviceFramesProvider } from "UsabilityHub/contexts"
import React, { PropsWithChildren, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { apiUsabilityTests } from "~/api"
import PayoutsApi from "~/api/api/admin/payoutsApi"
import ResponsesApi from "~/api/api/admin/responsesApi"
import UsabilityTestResultsApi from "~/api/api/admin/usabilityTestResultsApi"

type Props = {
  usabilityTestUniqueId: string | null
  privateId: string | null
  useSharedApi: boolean
  responseId: number | null
  isAdmin: boolean
  orderId: number | null
  isThirdPartyOrder: boolean
  revising: boolean
}

interface ResultUsabilityTestResponseData {
  device_frames: DeviceFrame[]
  screenshots: Screenshot[]
  languages: Language[]
  projects: string[]
  test_results: PresentRawTestResultsState
  usability_tests: ShallowUsabilityTest[]
}

export const ResultDataProvider: React.FC<PropsWithChildren<Props>> = ({
  usabilityTestUniqueId,
  useSharedApi,
  responseId,
  isAdmin,
  privateId,
  orderId,
  isThirdPartyOrder,
  revising,
  children,
}) => {
  const toast = useToast()
  const dispatch = useDispatch<Dispatch>()

  const [deviceFrames, setDeviceFrames] = useState<DeviceFrame[]>([])
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    const apiFunction = determineApi({
      usabilityTestUniqueId,
      privateId,
      useSharedApi,
      isAdmin,
      responseId,
      orderId,
      isThirdPartyOrder,
      revising,
    })

    const fetchData = async () => {
      try {
        const data = await apiFunction()

        if (!data) {
          return void setIsInitialized(true)
        }

        const initializedScreenshots = getInitializeScreenshots(
          data.screenshots
        )

        dispatch(setScreenshots(initializedScreenshots))
        dispatch(initializeTestResults(data.test_results))
        dispatch(initUsabilityTests(data.usability_tests))

        setDeviceFrames(data.device_frames)

        setIsInitialized(true)
      } catch (e) {
        console.error(e)

        if (isAxiosError(e)) {
          if (isNotFoundError(e)) {
            return void toast({
              title: "Test does not exist",
              status: "error",
            })
          }
          const errorMessage = e.response?.data.message as string | undefined

          toast({
            title:
              errorMessage ??
              "Lyssna has encountered an error while fetching data",
            status: "error",
          })
        } else {
          toast({
            title: "Lyssna has encountered an error while fetching data",
            status: "error",
          })
        }
      }
    }

    void fetchData()
  }, [])

  if (!isInitialized)
    return (
      <Flex mt="32" justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    )

  return (
    <DeviceFramesProvider deviceFrames={deviceFrames}>
      {children}
    </DeviceFramesProvider>
  )
}

const determineApi = ({
  useSharedApi,
  isAdmin,
  responseId,
  orderId,
  isThirdPartyOrder,
  usabilityTestUniqueId,
  privateId,
  revising,
}: {
  useSharedApi: boolean
  isAdmin: boolean
  responseId: number | null
  orderId: number | null
  isThirdPartyOrder: boolean
  usabilityTestUniqueId: string | null
  privateId: string | null
  revising: boolean
}) => {
  if (isAdmin) {
    if (responseId) {
      // If we have a responseId we're viewing a single response results page
      return async () =>
        ResponsesApi.get<ResultUsabilityTestResponseData>({
          params: { id: responseId },
        })
    } else if (orderId) {
      // If we have an orderId we're on the "review order" version of the results page
      // Could be a third party order or a Lyssna panel order
      if (isThirdPartyOrder) {
        return async () =>
          PayoutsApi.thirdPartyOrder<ResultUsabilityTestResponseData>({
            id: orderId,
            query: { revise: revising },
          })
      } else {
        return async () =>
          PayoutsApi.order<ResultUsabilityTestResponseData>({
            orderId,
            query: { revise: revising },
          })
      }
    } else {
      // Otherwise we're on the normal admin "view results" page
      return async () =>
        UsabilityTestResultsApi.get<ResultUsabilityTestResponseData>({
          params: { id: usabilityTestUniqueId },
        })
    }
  } else {
    if (useSharedApi) {
      return async () =>
        apiUsabilityTests.sharedResults<ResultUsabilityTestResponseData>({
          params: {
            usability_test_id: usabilityTestUniqueId,
            private_id: privateId,
          },
        })
    }

    return async () =>
      apiUsabilityTests.results<ResultUsabilityTestResponseData>({
        params: {
          usability_test_id: usabilityTestUniqueId,
        },
      })
  }
}
