import { isEmpty, isEqual, isPlainObject } from "lodash"
import { connect } from "react-redux"
import { compose } from "redux"
import { reduxForm } from "redux-form"

import { Dispatch, State } from "Redux/app-store"
import { getFigmaFileVersions } from "Redux/reducers/figma-file-versions/selectors"
import { getScreenshots } from "Redux/reducers/screenshots/selectors"
import { getFormName } from "Redux/reducers/test-builder-form/selectors/formValues"
import { setIsEstimateValid } from "Redux/reducers/test-builder/action-creators/estimate"
import { preview, save } from "Redux/reducers/test-builder/action-creators/save"
import { getIsEstimateValid } from "Redux/reducers/test-builder/selectors/estimate"
import { getInitialValues } from "Redux/reducers/test-builder/selectors/test-form"
import { getIsPreviewClicked } from "Redux/reducers/test-builder/selectors/test-form"
import {
  updateUsabilityTestRequest,
  updateUsabilityTestSuccess,
} from "Redux/reducers/usability-tests/action-creators"
import { UsabilityTest } from "Types"
import { beforeUnloadHandler } from "Utilities/before-unload-handler"
import { reportError } from "Utilities/error"

import { usabilityTestRecruitPath } from "Shared/constants/routes"
import { TestFormFields } from "./TestFormFields"
import { hasUsabilityTestLengthChanged } from "./hasUsabilityTestLengthChanged"
import { validateTest } from "./validators/validateTest"

const [preventUnload, permitUnload] = beforeUnloadHandler()

type ContainerProps = ReturnType<typeof connectStateToProps>

const queryParams = new URLSearchParams(window.location.search)
const screenerEnabledOnLoad: boolean = queryParams.get("name") === "Screener"
if (screenerEnabledOnLoad) {
  // Remove `?name=Screener` from the URL
  window.history.replaceState(null, "", window.location.pathname)
}

const connectStateToProps = (state: State) => {
  const values = getInitialValues(state)
  // If we don't have an ID, we're creating a new test so we can override the
  // initial screener values
  const initialValues =
    values.id === null
      ? {
          ...values,
          screener: { enabled: screenerEnabledOnLoad, questions: [] },
        }
      : values

  return {
    initialValues,
    isPreviewClicked: getIsPreviewClicked(state),
    isEstimateValid: getIsEstimateValid(state),
    screenshots: getScreenshots(state),
    figmaFileVersions: getFigmaFileVersions(state),
  }
}

function isObject(value: unknown): value is Record<string, unknown> {
  return isPlainObject(value)
}

// Recursively strip the `_clientId` field from a deeply nested object since we
// don't care if they are changing.
function removeClientIds<T>(values: T): unknown {
  if (!isObject(values)) return values

  return Object.fromEntries(
    Object.entries(values).map(([key, value]) => {
      if (key === "_clientId") return [key, undefined]
      if (Array.isArray(value))
        return [key, value.map((v) => removeClientIds(v))]

      return [key, removeClientIds(value)]
    })
  )
}

const onFormChange = (
  values: Readonly<UsabilityTest>,
  dispatch: Dispatch,
  props: ContainerProps,
  previousValues: Readonly<UsabilityTest>
) => {
  // Redux-form is 💩
  if (isEmpty(values) || isEmpty(previousValues)) return
  // If the only thing that has changed is the clientIds, break out early
  if (isEqual(removeClientIds(values), removeClientIds(previousValues))) return

  preventUnload()

  // If our estimate was valid but out test length changed we need
  // to invalidate it so the sidebar knows to fetch a new one once
  // the form validates. Field-level validation happens _after_
  // onChange so we let the component decide when to make the call
  if (
    props.isEstimateValid &&
    hasUsabilityTestLengthChanged(values, previousValues)
  ) {
    dispatch(setIsEstimateValid(false))
  }
}

const onFormSubmit = async (
  _values: Readonly<UsabilityTest>,
  dispatch: Dispatch,
  props: ContainerProps
) => {
  const isNewTest = _values.id === null
  try {
    !isNewTest && dispatch(updateUsabilityTestRequest(_values.id))
    if (props.isPreviewClicked) {
      const updatedUsabilityTest = await dispatch(preview())
      // Only unload if saving succeeds
      permitUnload()

      !isNewTest &&
        dispatch(
          updateUsabilityTestSuccess(
            updatedUsabilityTest.id,
            updatedUsabilityTest
          )
        )
    } else {
      const updatedUsabilityTest = await dispatch(save())
      // Only unload if saving succeeds
      permitUnload()

      // Cannot use react router history to push route change because of flash used in the above save() thunk action
      // the basic flash component that is reading from the redux store is embedded in the Page component, meaning we get 2 flashes,
      // because it is calling the chakra toast function, once from the old page and one from the newly mounted page
      // Also while the flash is visible, on every page transition we get a new flash message because Page is mounted
      // This did not use to be a problem with full page loads as the "old" toasts are forgotten about anyways

      // To make this work properly we need to use the chakra toast, but since its needing a hook to get us the toast function, we cannot use it in here.
      // We could create a wrapper around the entire component and pass it the toast function
      window.location.href = usabilityTestRecruitPath(
        updatedUsabilityTest.unique_id
      )

      !isNewTest &&
        dispatch(
          updateUsabilityTestSuccess(
            updatedUsabilityTest.id,
            updatedUsabilityTest
          )
        )
    }
  } catch (error) {
    reportError(error)
  }
}

export const TestFormFieldsContainer = compose(
  connect(connectStateToProps),
  reduxForm<UsabilityTest, ContainerProps>({
    onChange: onFormChange,
    onSubmit: onFormSubmit,
    form: getFormName(),
    destroyOnUnmount: !module.hot, // https://github.com/erikras/redux-form/issues/623
    enableReinitialize: true,
    validate: validateTest,
  })
)(TestFormFields)
