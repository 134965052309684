import { flatMap, get } from "lodash"
import { arrayMove, arrayPush, arrayRemove, arraySplice } from "redux-form"

import { State } from "Redux/app-store"
import { showErrorMessage } from "Redux/reducers/flash"
import { uploadScreenshots } from "Redux/reducers/screenshots/action-creators"
import {
  getFormName,
  getFormValues,
} from "Redux/reducers/test-builder-form/selectors/formValues"
import { createCheckpoint } from "Redux/reducers/test-builder/action-creators/undo"
import { SaveDeletedSectionScreenshotAction } from "Redux/reducers/test-builder/action-types"
import { Type } from "Redux/reducers/test-builder/constants"
import { isPersisted } from "Redux/reducers/test-builder/helpers"
import {
  AsyncThunkAction,
  ClientId,
  S3Config,
  Screenshot,
  ThunkAction,
  UsabilityTestSectionScreenshot,
} from "Types"
import { fileToScreenshot } from "~/application/javascripts/services/screenshot"
import { ValidateFilesResult } from "~/application/javascripts/services/validate-files"
import { nextClientId } from "~/application/javascripts/utilities/client-id"
import { reportError } from "~/application/javascripts/utilities/error"

export const addScreenshots =
  (
    sectionIndex: number,
    validatedFiles: Readonly<ValidateFilesResult>,
    s3Config: S3Config
  ): AsyncThunkAction<State> =>
  async (dispatch, getState) => {
    const { errors, validFiles, extraFiles } = validatedFiles
    // Show Errors, of invalid files
    errors.forEach((error) => {
      dispatch(showErrorMessage(error))
    })

    // If there aren't any files
    if (validFiles.length === 0) return

    // Show warning if there are to many files
    if (extraFiles.length > 0) {
      dispatch(
        showErrorMessage(
          `Oops — too many images chosen! I skipped ${extraFiles.length} of those.`
        )
      )
    }

    // Process screenshots
    const screenshots = await Promise.all(validFiles.map(fileToScreenshot))
    // This should be refactored to not use a promise within a forEach loop
    screenshots.forEach(async (screenshot) => {
      const fieldName = `sections[${sectionIndex}].section_screenshots`
      const screenshotSectionClientId = nextClientId()
      const screenshotClientId = screenshot._clientId

      // Update the screenshot_id
      try {
        // Start uploading the screenshot
        const uploading = dispatch(uploadScreenshots([screenshot], s3Config))

        // Add the screenshot to the form
        dispatch(
          arrayPush(getFormName(), fieldName, {
            _clientId: screenshotSectionClientId,
            _screenshotClientId: screenshotClientId,
            _name: screenshot.name,
            _display_scale: screenshot.display_scale,
            screenshot_id: null,
            screenshot_hitzones: [],
            archived: false,
          })
        )

        // Wait for the screenshot to upload
        const uploads = await uploading

        // Update the screenshot id
        dispatch(
          updateScreenshotSection(screenshotClientId, {
            screenshot_id: (uploads[0] as Screenshot).id as number,
          })
        )
      } catch (error) {
        reportError(error)
        // If it failed, remove it from the form
        const sectionScreenshots: ReadonlyArray<UsabilityTestSectionScreenshot> =
          get(getFormValues(getState()), fieldName, [])
        const index = sectionScreenshots.findIndex(
          (v) => v._screenshotClientId === screenshotClientId
        )
        if (index === -1) return
        dispatch(arrayRemove(getFormName(), fieldName, index))
      }
    })
  }

export const updateScreenshotSection =
  (
    clientId: ClientId,
    attributes: Partial<UsabilityTestSectionScreenshot>
  ): ThunkAction<State> =>
  (dispatch, getState) => {
    const values = getFormValues(getState())

    // Get all screenshots that map the id
    const indexes = flatMap(values.sections, (section, sectionIndex) =>
      section.section_screenshots
        .map((s, screenshotIndex) => ({
          sectionIndex,
          screenshotIndex,
          _screenshotClientId: s._screenshotClientId,
        }))
        .filter((s) => s._screenshotClientId === clientId)
    )

    // Update all screenshots in all sections that match
    indexes.forEach(({ sectionIndex, screenshotIndex }) => {
      const field = `sections[${sectionIndex}].section_screenshots`
      const value = get(values, `${field}[${screenshotIndex}]`, {})

      dispatch(
        arraySplice(getFormName(), field, screenshotIndex, 1, {
          ...value,
          ...attributes,
        })
      )
    })
  }

export const deleteSectionScreenshot =
  (sectionIndex: number, sectionScreenshotIndex: number): ThunkAction<State> =>
  (dispatch, getState) => {
    const usabilityTest = getFormValues(getState())
    const section = usabilityTest.sections[sectionIndex]
    const field = `sections[${sectionIndex}].section_screenshots`
    const sectionScreenshot = get(usabilityTest, field)[
      sectionScreenshotIndex
    ] as UsabilityTestSectionScreenshot

    dispatch(
      createCheckpoint({
        type: "delete-screenshot",
        sectionId: section.id,
        sectionIndex,
        sectionScreenshotIndex,
        sectionScreenshot,
      })
    )

    if (isPersisted(sectionScreenshot)) {
      dispatch<SaveDeletedSectionScreenshotAction>({
        type: Type.SAVE_DELETED_TEST_FORM_SECTION_SCREENSHOT,
        payload: {
          sectionId: section.id,
          sectionScreenshotId: sectionScreenshot.id,
        },
      })
    }

    dispatch(arrayRemove(getFormName(), field, sectionScreenshotIndex))
  }

export const moveScreenshot =
  (sectionIndex: number, from: number, to: number): ThunkAction<State> =>
  (dispatch) => {
    dispatch(
      arrayMove(
        getFormName(),
        `sections[${sectionIndex}].section_screenshots`,
        from,
        to
      )
    )
  }
