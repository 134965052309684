import { Button, Icon, Text } from "@chakra-ui/react"
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid"
import React from "react"

import { useAvailabilitySectionForm } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/forms/useAvailabilitySectionForm"
import { useDeviceRequirementForm } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/forms/useDeviceRequirementForm"
import { useMeetingUrlForm } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/forms/useMeetingUrlForm"
import { useStudyDetailsForm } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/forms/useStudyDetailsForm"
import { useTeamMembersForm } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/forms/useTeamMembersForm"

type Props = {
  studyDetailsForm: ReturnType<typeof useStudyDetailsForm>
  meetingUrlForm: ReturnType<typeof useMeetingUrlForm>
  deviceRequirementForm: ReturnType<typeof useDeviceRequirementForm>
  availabilityForm: ReturnType<typeof useAvailabilitySectionForm>
  teamMembersForm: ReturnType<typeof useTeamMembersForm>
  // This is a single synthetic form object aggregating all the screener questions
  screenerQuestions: {
    formState: { errors: Array<string>; isScreenerEnabled: boolean }
  }
}

// This is an array of sections to show on the sidebar. They will each have an icon that shows
// the overall validation status of the section. The `fields` array is a list of form fields that will
// be checked for errors, since there's not a 1-to-1 relationship between forms and sections.
// You can use an asterisk to check every field in the form.
const SECTIONS = [
  {
    name: "Study details",
    fields: [
      ["studyDetailsForm", "*"],
      ["deviceRequirementForm", "*"],
      ["meetingUrlForm", "*"],
      ["availabilityForm", "duration"],
    ],
  },
  {
    name: "Screener",
    fields: [["screenerQuestions", "*"]],
  },
  {
    name: "Team members",
    fields: [["teamMembersForm", "*"]],
  },
  {
    name: "Availability",
    fields: [
      // Everything _except_ duration, which appears above
      ["availabilityForm", "startsAt"],
      ["availabilityForm", "endsAt"],
      ["availabilityForm", "timezone"],
      ["availabilityForm", "eventDurationMins"],
      ["availabilityForm", "preEventBufferMins"],
      ["availabilityForm", "postEventBufferMins"],
      ["availabilityForm", "availabilityByDay"],
      ["availabilityForm", "availabilityOverrides"],
    ],
  },
  {
    name: "Automated emails",
    fields: [],
  },
] as const

type SectionStatusData = {
  name: (typeof SECTIONS)[number]["name"]
  icon: typeof CheckCircleIcon | typeof ExclamationCircleIcon
  iconColor: "gray.200" | "orange.400" | "green.400"
}

const getSectionStatusesData = (props: Props): SectionStatusData[] => {
  const data = SECTIONS.map<SectionStatusData>((section) => {
    if (
      section.name === "Screener" &&
      !props.screenerQuestions.formState.isScreenerEnabled
    ) {
      return {
        name: section.name,
        icon: CheckCircleIcon,
        iconColor: "gray.200",
      }
    }

    const anyErrors =
      section.fields.length &&
      section.fields.some(([form, field]) => {
        // For wildcard, check if the entire form has any errors at all
        if (field === "*") {
          return Object.keys(props[form].formState.errors).length > 0
        }
        // Otherwise we're only checking a specific field
        return field in props[form].formState.errors
      })
    return {
      name: section.name,
      icon: anyErrors ? ExclamationCircleIcon : CheckCircleIcon,
      iconColor: anyErrors ? "orange.400" : "green.400",
    }
  })

  return data
}

export const ModeratedStudySectionStatuses: React.FC<Props> = (props) => {
  const sectionStatusesData = getSectionStatusesData(props)

  return (
    <>
      {sectionStatusesData.map(({ name, icon, iconColor }) => {
        return (
          <Button
            key={name}
            w="full"
            h="auto"
            bg="white"
            p="3"
            borderColor="gray.300"
            borderWidth="thin"
            rounded="md"
            justifyContent="space-between"
            alignItems="center"
            _hover={{ bg: "gray.50" }}
            _active={{ bg: "gray.100" }}
            onClick={() => {
              const sectionId = name.toLowerCase().replace(" ", "_")
              const element = document.getElementById(sectionId)

              if (element) {
                const y =
                  element.getBoundingClientRect().top + window.scrollY - 50

                window.scrollTo({
                  top: y,
                  behavior: "smooth",
                })
              }
            }}
          >
            <Text color="text.primary" fontWeight="medium" lineHeight={1.5}>
              {name}
            </Text>
            <Icon as={icon} textColor={iconColor} boxSize="5" />
          </Button>
        )
      })}
    </>
  )
}
