import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Radio,
  RadioGroup,
  Select,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react"
import { ExternalLinkIcon } from "@heroicons/react/outline"
import { CommentThread } from "Components/comment-thread/CommentThread"
import Constants from "Constants/shared.json"
import { Alert, Badge, Heading } from "DesignSystem/components"
import {
  getFormValue,
  getFormValues,
} from "Redux/reducers/test-builder-form/selectors/formValues"
import { Copy06OutlineIcon } from "Shared/icons/untitled-ui/Copy06OutlineIcon"
import {
  TestCard,
  TestCardHeading,
} from "UsabilityHub/components/TestCard/TestCard"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import { validateUrl } from "UsabilityHub/components/TestForm/validators/validateUrl"
import {
  useSectionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"
import React, { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Field, WrappedFieldProps, change } from "redux-form"

export const ExternalStudySectionCard: React.FC = () => {
  const dispatch = useDispatch()
  const sectionIndex = useSectionIndexContext()
  const sectionPath = `sections[${sectionIndex}].external_study_attributes`

  const completionCode = useSelector(
    getFormValue(`${sectionPath}.completion_code`)
  )

  useEffect(() => {
    if (!completionCode) {
      const randomCode = Math.random().toString(36).slice(2, 8)
      dispatch(
        change("test-form", `${sectionPath}.completion_code`, randomCode)
      )
    }
  }, [completionCode])

  return (
    <MinimalSectionCard>
      <TestCardHeading>External study</TestCardHeading>

      <Flex direction="column" mt={6} gap={6}>
        <Flex direction="column" gap={3}>
          <Heading as="h3" textStyle="ds.heading.primary">
            What's the URL of your study?
          </Heading>

          <Field
            name={`${sectionPath}.url`}
            component={UrlField}
            validate={validateUrl}
            validateOnBlur
          />
        </Flex>

        <Box as="hr" mx={-8} />

        <Flex direction="column" gap={3}>
          <Heading as="h3" textStyle="ds.heading.primary">
            Estimated duration
          </Heading>

          <Text>
            Enter how long you expect participants to spend completing your
            study.
          </Text>

          <Field
            name={`${sectionPath}.estimated_duration_in_minutes`}
            component={EstimatedDurationField}
            validate={(value: number) => {
              if (value === undefined || value === null) {
                return "You must provide an estimated duration"
              } else if (value < 1) {
                return "Estimated duration must be at least 1 minute"
              }
            }}
          />
        </Flex>

        <Box as="hr" mx={-8} />

        <Flex direction="column" gap={3}>
          <Heading as="h3" textStyle="ds.heading.primary">
            Link responses to panelists
          </Heading>

          <Text sx={{ textWrap: "balance" }}>
            Choose how to link responses in your research tool to the panelists
            who provided them. This will allow you to submit feedback on quality
            for each response in Lyssna.
          </Text>

          <Field
            name={`${sectionPath}.participation_strategy`}
            component={ParticipationStrategyField}
            validate={(value: string) => {
              if (!value) {
                return "You must choose how you want to link Lyssna panelist IDs"
              }
            }}
          />
        </Flex>

        <Box as="hr" mx={-8} />

        <Flex direction="column" gap={3}>
          <Heading as="h3" textStyle="ds.heading.primary">
            Communicate a completion code
          </Heading>

          <Text sx={{ textWrap: "balance" }}>
            Choose how to track that participants have completed your study to
            ensure they can be compensated.
          </Text>

          <Field
            name={`${sectionPath}.completion_strategy`}
            component={CompletionStrategyField}
            validateOnBlur
            validate={(value: string) => {
              if (!value) {
                return "You must choose how you want to provide the completion code"
              }
            }}
          />

          <Field
            component="input"
            type="hidden"
            name={`${sectionPath}.completion_code`}
          />
        </Flex>
      </Flex>
    </MinimalSectionCard>
  )
}

// Similar to SectionCard, but without a header and other controls like test logic, deletion, etc.
const MinimalSectionCard: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const sectionIndex = useSectionIndexContext()
  const { section } = useSectionContext()
  const commentableEntity = useMemo(
    () =>
      ({
        entityContext: "test_builder",
        entityType: "usability_test_section",
        entityId: section.id ? String(section.id) : section._clientId,
      }) as const,
    [section.id, section._clientId]
  )

  return (
    <TestCard
      id={`section_${sectionIndex}`}
      commentableEntity={commentableEntity}
      position="relative"
    >
      <CommentThread
        entity={commentableEntity}
        isEntityPersisted={section.id !== null}
        offsetX={22}
        offsetY={16}
      />

      {children}
    </TestCard>
  )
}

const UrlField: React.FC<WrappedFieldProps> = ({ input, meta }) => {
  const { readOnly } = useUsabilityTestUserActivityContext()

  const formValues = useSelector(getFormValues)

  const externalStudy = formValues.sections[0].external_study_attributes
  const participationStrategy = externalStudy?.participation_strategy

  return (
    <FormControl isInvalid={!!meta.error}>
      <InputGroup>
        <InputLeftAddon>
          <Icon as={ExternalLinkIcon} color="gray.500" />
        </InputLeftAddon>
        <Input
          isReadOnly={readOnly}
          placeholder="https://example.com"
          {...input}
        />
        {participationStrategy === "query_parameters" && (
          <InputRightAddon>
            {input.value.includes("?") ? "&" : "?"}
            fake_query_parameter=fake_value
          </InputRightAddon>
        )}
      </InputGroup>

      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}

const EstimatedDurationField: React.FC<WrappedFieldProps> = ({
  input,
  meta,
}) => {
  const { readOnly } = useUsabilityTestUserActivityContext()

  return (
    <FormControl isInvalid={!!meta.error}>
      <Select maxW="fit-content" isReadOnly={readOnly} {...input}>
        <option value="">Choose a duration</option>
        {/* If you change the options here, also update the
            backend validation in the ExternalStudy model */}
        <option value="2">1-2 minutes</option>
        <option value="5">3-5 minutes</option>
        <option value="10">6-10 minutes</option>
        <option value="15">11-15 minutes</option>
        <option value="20">16-20 minutes</option>
        <option value="25">21-25 minutes</option>
        <option value="30">26-30 minutes</option>
      </Select>

      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}

const ParticipationStrategyField: React.FC<WrappedFieldProps> = ({
  input,
  meta,
}) => {
  return (
    <FormControl isInvalid={!!meta.error}>
      <RadioGroup {...input}>
        <Flex direction="column" gap={3} ps={2}>
          <Flex align="center" gap={2}>
            <Radio
              isDisabled
              name="participation_strategy"
              value="query_parameters"
            >
              I{"\u2019"}ll use URL parameters
            </Radio>

            <Badge label="Coming soon" colorScheme="new" />
          </Flex>

          {input.value === "query_parameters" && (
            <Alert
              status="info"
              description="Set up your study to automatically send participants to the provided URL. This is the ideal experience, as we can capture the completion code in the URL, and participants are not required to take additional action."
            />
          )}

          <Radio name="participation_strategy" value="copy_paste">
            I{"\u2019"}ll add a question in my study
          </Radio>

          {input.value === "copy_paste" && (
            <Alert
              status="info"
              description={
                <>
                  <Text>
                    Please add a new question to the beginning of your study
                    asking participants for their unique Panelist ID.
                  </Text>

                  <Text>Suggested question: What is your Panelist ID?</Text>
                </>
              }
            />
          )}
        </Flex>
      </RadioGroup>

      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}

const CompletionStrategyField: React.FC<WrappedFieldProps> = ({
  input,
  meta,
}) => {
  const toast = useToast()
  const formValues = useSelector(getFormValues)

  const usabilityTestId = formValues.unique_id
  const externalStudy = formValues.sections[0].external_study_attributes
  const completionCode = externalStudy?.completion_code ?? "SAVEFIRST"

  const redirectURL = `${Constants.PANELIST_EXTERNAL_STUDY_REDIRECT_URL_PREFIX}/${usabilityTestId}/${completionCode}`

  const copyCompletionCodeToClipboard = async () => {
    await navigator.clipboard.writeText(completionCode)

    toast({
      title: "Completion code copied to clipboard",
      status: "success",
    })
  }

  const copyRedirectURLToClipboard = async () => {
    await navigator.clipboard.writeText(redirectURL)

    toast({
      title: "Redirect URL copied to clipboard",
      status: "success",
    })
  }

  return (
    <FormControl isInvalid={!!meta.error}>
      <RadioGroup {...input}>
        <Flex direction="column" gap={3} ps={2}>
          <Radio name="participation_strategy" value="redirect_url">
            I{"\u2019"}ll use a redirect URL
          </Radio>
          {input.value === "redirect_url" && (
            <>
              <Alert
                status="info"
                description={
                  <>
                    <Text>
                      Set up your study to automatically redirect participants
                      to this URL after completing it.{" "}
                    </Text>
                    <Text>
                      The URL contains the study’s completion code, so panelists
                      who follow it are marked as having completed the study.
                    </Text>
                  </>
                }
              />
              <InputGroup>
                <InputLeftAddon>Redirect URL</InputLeftAddon>
                <Flex
                  align="center"
                  borderTopWidth={1}
                  borderBottomWidth={1}
                  px={3}
                >
                  <Text>{redirectURL}</Text>
                </Flex>
                <InputRightAddon>
                  <Tooltip hasArrow placement="top" label="Copy to clipboard">
                    {/* Having this as an IconButton breaks redux-form in a weird way */}
                    <Icon
                      as={Copy06OutlineIcon}
                      aria-label="Copy redirect URL to clipboard"
                      onClick={copyRedirectURLToClipboard}
                      cursor="pointer"
                    />
                  </Tooltip>
                </InputRightAddon>
              </InputGroup>
            </>
          )}
          <Radio name="participation_strategy" value="prompt">
            I{"\u2019"}ll provide the completion code in my study
          </Radio>
          {input.value === "prompt" && (
            <>
              <Alert
                status="info"
                description={
                  <>
                    <Text>
                      Please add this completion code to the last page that a
                      panelist will see after completing the study.
                    </Text>
                    <Text>
                      The panelist will copy and paste this code back into our
                      platform to prove completion.
                    </Text>
                  </>
                }
              />

              <InputGroup>
                <InputLeftAddon>Completion code</InputLeftAddon>

                <Flex
                  align="center"
                  borderTopWidth={1}
                  borderBottomWidth={1}
                  px={3}
                >
                  <Text maxW="200px">{completionCode}</Text>
                </Flex>

                <InputRightAddon>
                  <Tooltip hasArrow placement="top" label="Copy to clipboard">
                    {/* Having this as an IconButton breaks redux-form in a weird way */}
                    <Icon
                      as={Copy06OutlineIcon}
                      aria-label="Copy completion code to clipboard"
                      onClick={copyCompletionCodeToClipboard}
                      cursor="pointer"
                    />
                  </Tooltip>
                </InputRightAddon>
              </InputGroup>
            </>
          )}
        </Flex>
      </RadioGroup>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
