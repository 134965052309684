import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
} from "@chakra-ui/react"
import { map } from "lodash"
import React, { useMemo } from "react"
import { Field, WrappedFieldProps } from "redux-form"

import { useLanguages } from "Hooks/use-languages"
import { useTranslate } from "Shared/hooks/useTranslate"
import { PermittedDeviceType } from "Types"

import { Alert } from "DesignSystem/components/Alert"
import { getSections } from "Redux/reducers/test-builder-form/selectors/sections"
import { getId } from "Redux/reducers/test-builder/selectors/test-form"
import { useIsTestBuilderExternalStudy } from "UsabilityHub/hooks/useIsTestBuilderExternalStudy"
import { useSelector } from "react-redux"
import { useUsabilityTestUserActivityContext } from "../../UsabilityTestUserActivityContext"

// For each field we define the FormControl which gets redux-form’s
// WrappedFieldProps inserted by the Field component, which we pass
// to the underlying form element
const TestNameFormControl = ({ input }: WrappedFieldProps) => {
  const { readOnly } = useUsabilityTestUserActivityContext()
  const usabilityTestId = useSelector(getId)!
  const isNewTest = !usabilityTestId

  return (
    <FormControl id="name">
      <FormLabel>Test name</FormLabel>
      <Input
        autoFocus={isNewTest}
        isReadOnly={readOnly}
        placeholder="Enter test name"
        required
        {...input}
      />
    </FormControl>
  )
}

const TestLanguageFormControl = ({ input }: WrappedFieldProps) => {
  const languages = useLanguages()
  const { readOnly } = useUsabilityTestUserActivityContext()
  const isExternal = useIsTestBuilderExternalStudy()

  return (
    <FormControl id="language_code">
      <FormLabel>Language</FormLabel>
      <Tooltip
        hasArrow
        placement="top"
        isDisabled={!isExternal}
        label="We are limiting external studies to English for the Alpha"
      >
        <Select required isDisabled={readOnly || isExternal} {...input}>
          {languages.map(({ display_string, code }) => (
            <option key={code} value={code}>
              {display_string}
            </option>
          ))}
        </Select>
      </Tooltip>
    </FormControl>
  )
}

const TestPermittedDeviceTypeFormControl = ({ input }: WrappedFieldProps) => {
  const translate = useTranslate()
  const { readOnly } = useUsabilityTestUserActivityContext()

  const permittedDeviceTypes = map(PermittedDeviceType, (deviceType) => ({
    label: translate(`permitted_device_type.${deviceType}`),
    value: deviceType,
  }))

  const sections = useSelector(getSections)

  const hasScreenRecordingEnabled = useMemo(
    () => sections.some((section) => !!section.recording_attributes?.screen),
    [sections]
  )

  const mobileEnabled = useMemo(
    () => input.value !== PermittedDeviceType.DesktopOnly,
    [input.value]
  )

  return (
    <FormControl id="permitted_device_type">
      <FormLabel>Allow participation on</FormLabel>
      <RadioGroup {...input}>
        <HStack>
          {permittedDeviceTypes.map(({ label, value }) => (
            <Radio key={value} value={value} isDisabled={readOnly} flexGrow={1}>
              {label}
            </Radio>
          ))}
        </HStack>
      </RadioGroup>
      {hasScreenRecordingEnabled && mobileEnabled && (
        <Alert
          mt={3}
          status="warning"
          description={`You\u2019ve allowed participation on mobile devices for this test.
          Please note that mobile responses won\u2019t include screen recordings.`}
        />
      )}
    </FormControl>
  )
}

// Export each field individually, this should make it easier to
// migrate away from redux-form in the future
export const TestNameField: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Field name="name" component={TestNameFormControl} />
)
export const TestLanguageField: React.FC<
  React.PropsWithChildren<unknown>
> = () => <Field name="language_code" component={TestLanguageFormControl} />
export const TestPermittedDeviceTypeField: React.FC<
  React.PropsWithChildren<unknown>
> = () => (
  <Field
    name="permitted_device_type"
    component={TestPermittedDeviceTypeFormControl}
  />
)
