import { axios } from "Services/axios"
import EventyApi from "~/api/eventyApi"

export type EventyEvent =
  | "account.clicked_upsell_link"
  | "test.upgrade_required"
  | "viewed_credit_purchase_confirmation_form"
  | "viewed_interviews_panel_order_form"
  | "account.exported_heat_map"
  | "account.exported_click_map"
  | "account.exported_word_cloud"
  | "moderated_studies.viewed"
  | "account.viewed_trial_end_screen"

export function record(
  event: EventyEvent,
  properties: Readonly<Record<string, any>>
) {
  return axios.post<void>(EventyApi.record.path(), {
    event_name: event,
    properties,
  })
}
