import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Center,
  Link as ChakraLink,
  Flex,
  Heading,
  Icon,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { HelpPopover } from "Shared/components/HelpPopover/HelpPopover"
import { PageMain } from "Shared/components/Page/Page"
import { ClockFastForwardOutlineIcon } from "Shared/icons/untitled-ui/ClockFastForwardOutlineIcon"
import { InterviewDetails } from "UsabilityHub/components/InterviewDetails/InterviewDetails"
import { centsToDollarsAndCents } from "Utilities/currency"
import { formatDuration } from "date-fns"
import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import {
  GetModeratedStudyOrderQuoteResponse,
  useGetModeratedStudy,
  useListDemographics,
  useTrackEvent,
} from "~/api/generated/usabilityhub-components"
import {
  DemographicAttributeGroup,
  ModeratedStudy,
} from "~/api/generated/usabilityhubSchemas"
import { PurchaseConfirmationModal } from "~/application/javascripts/components/payments/purchase-confirmation-modal"
import { CreditPricePopover } from "../NewOrderPage/CreditPricePopover"
import { DemographicTargetingSummary } from "../NewOrderPage/DemographicTargetingSummary"
import { DemographicsPanel } from "../NewOrderPage/DemographicsPanel"
import { NoOfPanelistsSection } from "../NewOrderPage/NoOfPanelistsSection"
import {
  OrderFormProvider,
  useOrderForm,
} from "../NewOrderPage/OrderFormProvider"
import { ROUTES } from "../routes"
import { useInterviewOrder } from "./useInterviewOrder"

const DEFAULT_NUM_PARTICIPANTS = 5
const RESPONSE_SCALE = [1, 5, 10, 15, 20, 25]

const estimatedFulfillmentTimeTooltipText = (
  study: ModeratedStudy | undefined
): string => {
  const baseText = "This is the order's estimated fulfilment time."

  if (study?.screener_enabled) {
    return `${baseText} As this study includes a screener, fulfilment time may be longer.`
  } else {
    return baseText
  }
}

/**
 * Note:
 *
 * You can use the `enable_submit=true` query param to force the submit
 * button to be enabled for local dev purposes.
 */
export const InterviewOrder: React.FC = () => {
  const { moderatedStudyId } = useTypedParams(ROUTES.INTERVIEW.RECRUIT.ORDERS)
  const { data: demographics } = useListDemographics({})
  const { data: study } = useGetModeratedStudy({
    pathParams: { moderatedStudyId },
  })

  const { mutate: trackEvent } = useTrackEvent()

  useEffect(() => {
    if (study) {
      trackEvent({
        body: {
          event: "interview_order_form_viewed",
          event_properties: { study_id: study.id },
        },
      })
    }
  }, [study])

  if (!demographics) {
    return (
      <Center flexGrow={1}>
        <Spinner />
      </Center>
    )
  }

  return (
    <PageMain>
      <Flex direction="column" align="center">
        <Flex direction="column" align="center" my={10}>
          <Heading fontSize="2xl" fontWeight="normal" mb={2}>
            Recruit participants from the Lyssna Panel
          </Heading>
          <ChakraLink
            as={Link}
            variant="noUnderline"
            to={ROUTES.INTERVIEW.RECRUIT.buildPath({
              moderatedStudyId,
            })}
          >
            &larr; Back to recruitment options
          </ChakraLink>
        </Flex>

        <OrderFormProvider
          context="interviews"
          demographics={demographics}
          testHasScreener={false} // Even when an Interview study has a screener, we don't show the screener UI
          testHasRecordings={false}
          testIsExternal={false}
          defaultNumberOfPanelists={DEFAULT_NUM_PARTICIPANTS}
        >
          {study && (
            <InterviewOrderInner study={study} demographics={demographics} />
          )}
        </OrderFormProvider>
      </Flex>
    </PageMain>
  )
}

type InterviewOrderInnerProps = {
  study: ModeratedStudy
  demographics: DemographicAttributeGroup[]
}

const InterviewOrderInner: React.FC<InterviewOrderInnerProps> = ({
  study,
  demographics,
}) => {
  const queryString = new URLSearchParams(window.location.search)
  const forceEnableSubmit = queryString.get("enable_submit") !== null

  const [isConfirming, setIsConfirming] = useState(false)
  const [handpickPanelists, setHandpickPanelists] = useState(false)

  const {
    ageRange,
    targetLocations,
    selectedOptionIds,
    setFocusAttributeId,
    clearAgeRange,
    clearTargetLocations,
    clearAllOptionsForAttribute,
  } = useOrderForm()
  const { latestQuote, createOrderLoading, handleSubmit } = useInterviewOrder({
    moderatedStudyId: study.id,
    handpickPanelists,
    isConfirming,
    setIsConfirming,
  })

  const activeDemographicAttributes = demographics.flatMap((group) => {
    return group.demographic_attributes
      .map((attribute) => ({
        ...attribute,
        options: attribute.options?.filter((option) =>
          selectedOptionIds.includes(option.id)
        ),
      }))
      .filter((attribute) => attribute.options?.length)
  })

  return (
    <>
      <SimpleGrid
        w={["90%", null, "min(90vw, 928px)"]}
        columns={[1, null, 2]}
        gap={4}
        pb={10}
      >
        <Flex direction="column" gap={4}>
          <Flex
            direction="column"
            borderWidth="1px"
            bg="white"
            borderColor="gray.200"
            borderRadius="md"
          >
            <NoOfPanelistsSection responseScale={RESPONSE_SCALE}>
              Number of sessions
              <HelpPopover placement="top">
                <Text fontSize="md" fontWeight="normal">
                  Specify how many interview sessions you would like to book
                  with Lyssna panelists.
                </Text>
              </HelpPopover>
            </NoOfPanelistsSection>

            <SelectionMethod
              handpickPanelists={handpickPanelists}
              setHandpickPanelists={setHandpickPanelists}
            />
          </Flex>

          <Box
            borderWidth="1px"
            bg="white"
            borderColor="gray.200"
            borderRadius="md"
          >
            <DemographicsPanel
              showPresets
              demographics={demographics}
              activePanel="usabilityhub"
            />
          </Box>
        </Flex>

        <Flex
          direction="column"
          pos="sticky"
          top="58px"
          h="fit-content"
          borderWidth="1px"
          borderColor="gray.200"
          borderRadius="md"
          bg="white"
        >
          {study && <InterviewSummaryPanel study={study} />}

          <DemographicTargetingSummary
            showPresets
            ageRange={ageRange}
            targetLocations={targetLocations}
            activeDemographicAttributes={activeDemographicAttributes}
            setFocusAttributeId={setFocusAttributeId}
            validationErrors={{}} // Only relevant for third party panel which does not apply here
            clearAgeRange={clearAgeRange}
            clearTargetLocations={clearTargetLocations}
            clearDemographicAttribute={clearAllOptionsForAttribute}
          />

          {latestQuote ? (
            <>
              <InterviewQuotePanel quote={latestQuote} />

              <Alert w="auto" status="info" borderRadius="md" m={4}>
                <AlertIcon />

                <AlertDescription>
                  Should we be unable to fulfill a session, you will be refunded
                  accordingly.{" "}
                  <ChakraLink
                    isExternal
                    href={Constants.HELP_CENTER_INTERVIEW_RECRUITING_URL}
                  >
                    Read more
                  </ChakraLink>{" "}
                  about our policies on order fulfillment, cancelation and
                  participant privacy.
                </AlertDescription>
              </Alert>

              {latestQuote.estimated_fulfillment_time === null && (
                <Alert w="auto" status="warning" mx={4}>
                  <AlertIcon />
                  <Text color="text.primary">
                    We can{"\u2019"}t fulfil that order from the Lyssna panel.
                    Try expanding your geographic or demographic targeting to
                    improve feasibility.
                  </Text>
                </Alert>
              )}

              <Button
                colorScheme="brand.primary"
                mx={4}
                my={4}
                isLoading={createOrderLoading}
                isDisabled={
                  !forceEnableSubmit &&
                  latestQuote.estimated_fulfillment_time === null
                }
                onClick={handleSubmit}
              >
                Submit order
              </Button>

              {latestQuote.estimated_fulfillment_time && (
                <Flex align="center" py={4} w="fit-content" mx="auto">
                  <Icon as={ClockFastForwardOutlineIcon} mr={2} />
                  <Text color="text.primary">Estimated turnaround time:</Text>
                  <Tooltip
                    hasArrow
                    rounded="sm"
                    placement="bottom"
                    label={estimatedFulfillmentTimeTooltipText(study)}
                  >
                    <Text fontWeight="bold" color="green.500" ml={1}>
                      Up to{" "}
                      {formatDuration({
                        days:
                          latestQuote.estimated_fulfillment_time /
                          (60 * 60 * 24),
                      })}
                    </Text>
                  </Tooltip>
                </Flex>
              )}
            </>
          ) : (
            <Center minH="120px">
              <Spinner />
            </Center>
          )}
        </Flex>
      </SimpleGrid>
      {isConfirming && latestQuote && (
        <PurchaseConfirmationModal
          submitPurchaseAsync={async () => {
            handleSubmit()
          }}
          onCancel={() => setIsConfirming(false)}
          formattedPrice={centsToDollarsAndCents(latestQuote.price_in_cents)}
          heading={`Purchase ${latestQuote.credit_cost} credits`}
        />
      )}
    </>
  )
}

type InterviewQuotePanelProps = {
  quote: GetModeratedStudyOrderQuoteResponse
}

const InterviewQuotePanel: React.FC<InterviewQuotePanelProps> = ({ quote }) => {
  return (
    <Flex direction="column" px={4} gap={3} mt={3}>
      <Flex justify="space-between">
        <Text color="text.primary" display="inline-flex" alignItems="center">
          {quote.requested_response_count} participants &times;{" "}
          {quote.credits_per_response} credits{" "}
          <HelpPopover>
            <Text>
              Based on the duration, each session will cost{" "}
              {quote.credits_per_response} credits, inclusive of participant
              incentive.
            </Text>
          </HelpPopover>
        </Text>
        <Text color="text.primary">{quote.credit_cost} credits</Text>
      </Flex>

      <Flex justify="space-between">
        <Text color="text.primary">From your balance</Text>
        <Text color="text.primary">
          {quote.credit_cost - quote.credits_to_be_purchased} credits
        </Text>
      </Flex>

      <Flex justify="space-between">
        <Text color="text.primary">Credits required</Text>
        <Text color="text.primary">
          {quote.credits_to_be_purchased} credits
        </Text>
      </Flex>

      {quote.credits_to_be_purchased > 0 ? (
        <Flex justify="space-between">
          <Box display="inline-flex" alignItems="center">
            Cost per credit{" "}
            <HelpPopover>
              <CreditPricePopover scale={quote.credits_scale} />
            </HelpPopover>
          </Box>
          <Text>{quote.price_per_credit}</Text>
        </Flex>
      ) : null}

      <Flex justify="space-between">
        <Text color="text.primary" fontWeight="bold">
          Total cost (inc. incentives)
        </Text>
        <Text color="text.primary" fontWeight="bold">
          {centsToDollarsAndCents(quote.price_in_cents)}
        </Text>
      </Flex>
    </Flex>
  )
}

type InterviewSummaryPanelProps = {
  study: ModeratedStudy
}
const InterviewSummaryPanel: React.FC<InterviewSummaryPanelProps> = ({
  study,
}) => {
  return (
    <Box borderBottomWidth={1} borderColor="gray.200" pt={4} px={4}>
      <Heading
        fontSize="18px"
        fontWeight="semibold"
        mb={1}
        textColor="text.primary"
      >
        Order summary
      </Heading>
      <InterviewDetails
        showLanguage
        eventDurationMins={study.availability.event_duration_mins}
        deviceRequirement={study.device_requirement}
        hasScreener={study.screener_enabled}
      />
    </Box>
  )
}

type SelectionMethodProps = {
  handpickPanelists: boolean
  setHandpickPanelists: Dispatch<SetStateAction<boolean>>
}

const SelectionMethod: React.FC<SelectionMethodProps> = ({
  handpickPanelists,
  setHandpickPanelists,
}) => {
  const onChange = (value: "handpick" | "automatic") => {
    setHandpickPanelists(value === "handpick")
  }
  const value = handpickPanelists ? "handpick" : "automatic"

  return (
    <Box p={4} borderTop="1px solid" borderColor="gray.200">
      <Heading color="text.primary" fontWeight="normal" fontSize="md" mb={4}>
        Selection method
        <HelpPopover placement="top">
          You can choose to either allow eligible panelists to automatically{" "}
          book or manually review and invite panelists to your study.
        </HelpPopover>
      </Heading>
      <RadioGroup onChange={onChange} value={value}>
        <VStack>
          <VStack align="left">
            <Radio value="automatic" mb={0}>
              <Text color="text.primary">Automatic</Text>
            </Radio>
            <Text ml={6} color="text.secondary">
              Eligible panelists will be able to book a session with you right
              away.
            </Text>
          </VStack>
          <VStack align="left">
            <Radio value="handpick" mb={0}>
              <Text color="text.primary">Handpick</Text>
            </Radio>
            <Text ml={6} color="text.secondary">
              Review panelists based on their profile and screener responses,
              and handpick those you would like to send a booking invitation.
            </Text>
          </VStack>
        </VStack>
      </RadioGroup>
    </Box>
  )
}
