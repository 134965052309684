import {
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { Field, FieldProps, Form, Formik } from "formik"
import React from "react"
import UsersApi from "~/api/admin/usersApi"

import { RailsForm } from "Components/form/form"
import { axios, isBadRequestError } from "Services/axios"

type AdminBanAndSuspendTester = React.FC<
  React.PropsWithChildren<{
    bannedFromTesting: boolean
    suspendedFromTesting: boolean
    userId: number
  }>
>

export const AdminBanAndSuspendTester: AdminBanAndSuspendTester = ({
  bannedFromTesting,
  suspendedFromTesting,
  userId,
}) => {
  if (bannedFromTesting) {
    return <UnbanTester userId={userId} />
  }

  return (
    <Flex direction="row">
      <BanTester userId={userId} />
      {suspendedFromTesting ? (
        <UnsuspendTester userId={userId} />
      ) : (
        <SuspendTester userId={userId} />
      )}
    </Flex>
  )
}

type BanTester = React.FC<
  React.PropsWithChildren<{
    userId: number
  }>
>

const BanTester: BanTester = ({ userId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button size="sm" mr={2} colorScheme="red" onClick={onOpen}>
        Ban
      </Button>
      <BanAndSuspendModal
        userId={userId}
        variant="ban"
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}

type UnbanTester = React.FC<
  React.PropsWithChildren<{
    userId: number
  }>
>

const UnbanTester: UnbanTester = ({ userId }) => {
  return (
    <RailsForm method="PUT" action={UsersApi.unban.path({ id: userId })}>
      <Button size="sm" colorScheme="green" type="submit">
        Un-ban
      </Button>
    </RailsForm>
  )
}

type SuspendTester = React.FC<
  React.PropsWithChildren<{
    userId: number
  }>
>

interface BanReasonFormValues {
  recent_responses_do_not_match_reported_country: boolean
  ip_does_not_match_reported_country: boolean
  phone_number_does_not_match_reported_country: boolean
  malicious_activity: boolean
  multiple_accounts: boolean
  response_quality: boolean
}

const SuspendTester: SuspendTester = ({ userId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button size="sm" colorScheme="red" onClick={onOpen}>
        Suspend
      </Button>
      <BanAndSuspendModal
        userId={userId}
        variant="suspend"
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}

type UnsuspendTester = React.FC<
  React.PropsWithChildren<{
    userId: number
  }>
>

const UnsuspendTester: UnsuspendTester = ({ userId }) => {
  return (
    <RailsForm method="PUT" action={UsersApi.unsuspend.path({ id: userId })}>
      <Button size="sm" colorScheme="green" type="submit">
        Un-suspend
      </Button>
    </RailsForm>
  )
}

type BandAndSuspendModal = React.FC<
  React.PropsWithChildren<{
    userId: number
    variant: "ban" | "suspend"
    isOpen: boolean
    onClose: () => void
  }>
>

const BanAndSuspendModal: BandAndSuspendModal = ({
  isOpen,
  onClose,
  userId,
  variant,
}) => {
  let action: string
  let url: string

  switch (variant) {
    case "ban":
      action = "Ban"
      url = UsersApi.ban.path({ id: userId })
      break
    case "suspend":
      action = "Suspend"
      url = UsersApi.suspend.path({ id: userId })
      break
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>{action} this panelist</ModalHeader>
          <Formik<BanReasonFormValues>
            initialValues={{
              recent_responses_do_not_match_reported_country: false,
              ip_does_not_match_reported_country: false,
              phone_number_does_not_match_reported_country: false,
              malicious_activity: false,
              multiple_accounts: false,
              response_quality: false,
            }}
            onSubmit={async (values) => {
              try {
                await axios.put(url, {
                  reasons: values,
                })
                window.location.reload()
              } catch (error) {
                if (isBadRequestError(error)) {
                  alert(error.response.data.errors)
                } else {
                  throw error
                }
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form data-qa="ban-and-suspend-modal-form">
                <ModalBody>
                  <Stack>
                    <Text mb={2}>
                      Please specify your reasons for choosing to{" "}
                      {action.toLowerCase()} this panelist.
                    </Text>
                    <Field name="multiple_accounts">
                      {({ field }: FieldProps) => (
                        <Checkbox variant="mdWithSmFont" {...field}>
                          Multiple accounts
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="ip_does_not_match_reported_country">
                      {({ field }: FieldProps) => (
                        <Checkbox variant="mdWithSmFont" {...field}>
                          IP does not match reported country
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="a_voip_number_used">
                      {({ field }: FieldProps) => (
                        <Checkbox variant="mdWithSmFont" {...field}>
                          A VoIP number used
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="recent_responses_do_not_match_reported_country">
                      {({ field }: FieldProps) => (
                        <Checkbox variant="mdWithSmFont" {...field}>
                          Recent responses do not match reported country
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="response_quality">
                      {({ field }: FieldProps) => (
                        <Checkbox variant="mdWithSmFont" {...field}>
                          Poor response quality
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="dishonest_profile">
                      {({ field }: FieldProps) => (
                        <Checkbox variant="mdWithSmFont" {...field}>
                          Dishonest demographic profile
                        </Checkbox>
                      )}
                    </Field>
                    <Field name="malicious_activity">
                      {({ field }: FieldProps) => (
                        <Checkbox variant="mdWithSmFont" {...field}>
                          Responses were offensive or profane
                        </Checkbox>
                      )}
                    </Field>
                  </Stack>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="submit"
                    colorScheme="red"
                    isLoading={isSubmitting}
                  >
                    {action}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
