import { Select, Text, Textarea } from "@chakra-ui/react"
import { Flex, FormControl, FormErrorMessage, Input } from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import { Dispatch } from "Redux/app-store"
import { getFormName } from "Redux/reducers/test-builder-form/selectors/formValues"
import { CheckCircleOutlineIcon } from "Shared/icons/untitled-ui/CheckCircleOutlineIcon"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import {
  useSectionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"
import React from "react"
import { useDispatch } from "react-redux"
import { Field, WrappedFieldProps, arrayPush } from "redux-form"
import { SectionQuestions } from "../SectionQuestions/SectionQuestions"
import { LiveWebsiteTaskContextMenu } from "./LiveWebsiteTaskContextMenu"

type Props = {
  taskIndex: number
}

export const LiveWebsiteTask: React.FC<Props> = ({ taskIndex }) => {
  const dispatch: Dispatch = useDispatch()
  const { section } = useSectionContext()
  const sectionIndex = useSectionIndexContext()
  const taskPath = `sections[${sectionIndex}].live_website_test.live_website_test_tasks[${taskIndex}]`

  const taskQuestionIds = section.live_website_test?.live_website_test_tasks[
    taskIndex
  ].live_website_test_task_questions.map(
    (q) => q.usability_test_section_question_id ?? q._clientId
  )

  return (
    <Flex direction="column" gap={7}>
      <Flex justify="space-between">
        <Flex align="center" gap={3}>
          <CheckCircleOutlineIcon boxSize={5} color="ds.icon.subtle" />

          <Heading
            as="h4"
            color="ds.text.default"
            textStyle="ds.display.secondary"
          >
            Task {taskIndex + 1}
          </Heading>
        </Flex>

        <LiveWebsiteTaskContextMenu taskIndex={taskIndex} />
      </Flex>

      <Field name={`${taskPath}.url`} component={UrlField} />
      <Field name={`${taskPath}.instructions`} component={InstructionsField} />
      <Field
        name={`${taskPath}.estimated_duration_in_minutes`}
        component={DurationField}
      />

      <SectionQuestions
        questionIdAllowlist={taskQuestionIds}
        handleAddQuestion={(q) => {
          // Attach new question to the task in Redux
          dispatch(
            arrayPush(
              getFormName(),
              `${taskPath}.live_website_test_task_questions`,
              {
                _clientId: q._clientId,
              }
            )
          )
        }}
      />
    </Flex>
  )
}

const UrlField: React.FC<WrappedFieldProps> = ({ input, meta }) => {
  const { readOnly } = useUsabilityTestUserActivityContext()

  return (
    <FormControl isInvalid={!!meta.error}>
      <Flex direction="column" gap={3}>
        <Flex direction="column" gap={1.5}>
          <Text color="ds.text.default" textStyle="ds.heading.secondary">
            URL
          </Text>
          <Text color="ds.text.default" textStyle="ds.paragrah.secondary">
            The web address where the participant will be directed.
          </Text>
        </Flex>

        <Input
          isReadOnly={readOnly}
          placeholder="https://example.com"
          {...input}
        />
      </Flex>

      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}

const InstructionsField: React.FC<WrappedFieldProps> = ({ input, meta }) => {
  const { readOnly } = useUsabilityTestUserActivityContext()

  return (
    <FormControl isInvalid={!!meta.error}>
      <Flex direction="column" gap={3}>
        <Flex direction="column" gap={1.5}>
          <Text color="ds.text.default" textStyle="ds.heading.secondary">
            Instruction
          </Text>
          <Text color="ds.text.default" textStyle="ds.paragrah.secondary">
            Provide clear task instructions for the participant to follow on
            your live site, or ask them for feedback or to answer specific
            questions while browsing the website.
          </Text>
        </Flex>

        <Textarea
          isReadOnly={readOnly}
          placeholder="Instructions go here"
          {...input}
        />
      </Flex>

      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}

const DurationField: React.FC<WrappedFieldProps> = ({ input, meta }) => {
  const { readOnly } = useUsabilityTestUserActivityContext()

  return (
    <FormControl isInvalid={!!meta.error}>
      <Flex direction="column" gap={3}>
        <Flex direction="column" gap={1.5}>
          <Text color="ds.text.default" textStyle="ds.heading.secondary">
            Estimated task length
          </Text>
          <Text color="ds.text.default" textStyle="ds.paragrah.secondary">
            Indicate how much time participants should expect to spend on the
            task.
          </Text>
        </Flex>

        <Select maxW="fit-content" isReadOnly={readOnly} {...input}>
          <option value="2">1-2 minutes</option>
          <option value="5">3-5 minutes</option>
          <option value="10">6-10 minutes</option>
          <option value="15">11-15 minutes</option>
        </Select>
      </Flex>

      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
