import { isUrl } from "Utilities/url-regex"
import { Validator } from "redux-form"

const VALIDATE_URL_MESSAGE = "Study URL must be a valid URL"

export const validateUrl: Validator = (fieldValue: string) => {
  // We currently don't show this message anywhere, but we use it to work out
  // whether to show the sidebar alert.
  return isUrl(fieldValue) ? undefined : VALIDATE_URL_MESSAGE
}
