import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Link,
  Text,
} from "@chakra-ui/react"
import {
  SkipUsabilityTestForm,
  SkipUsabilityTestModal,
} from "Components/skip-usability-test-modal/skip-usability-test-modal"
import { getCurrentResponse } from "Redux/reducers/current-response/selectors"
import { axios } from "Services/axios"
import { ParticipantDeletionReason } from "Types"
import { DocumentPictureInPicture } from "UsabilityHub/components/DocumentPictureInPicture/DocumentPictureInPicture"
import { useDocumentPictureInPictureContext } from "UsabilityHub/components/DocumentPictureInPicture/DocumentPictureInPictureProvider"
import { ROUTES } from "UserCrowd/views/routes"
import React, { ComponentProps, useRef, useState } from "react"
import { useSelector } from "react-redux"
import responsesApi from "~/api/responsesApi"
import testInterfaceApi from "~/api/testInterfaceApi"
import { OuterProps } from "../../props"
import { LiveWebsiteTestControlPanel } from "./LiveWebsiteTestControlPanel"

type Props = {
  usabilityTestSection: OuterProps["usabilityTestSection"]
  isStarted: boolean
  handleStart: () => void
  handleFinish: () => void
}

const ENGLISH = {
  code: "en",
  display_string: "English",
  english_name: "English",
  is_supported: true,
  local_name: "English",
}

export const LiveWebsiteTestTaskActive: React.FC<Props> = ({
  usabilityTestSection,
  isStarted,
  handleStart,
  handleFinish,
}) => {
  const language = ENGLISH // Multi-language support will be in MOT-1042

  const {
    open: openPip,
    close: closePip,
    isOpen: isPipOpen,
    isSupported: isPipSupported,
    copyStyles: updatePipStyles,
  } = useDocumentPictureInPictureContext()
  const responseId = useSelector(getCurrentResponse)?.id

  const [isReportModalOpen, setIsReportModalOpen] = useState(false)
  const [showFullPageReportForm, setShowFullPageReportForm] = useState(false)
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0)
  const taskTab = useRef<Window | null>(null)

  if (!usabilityTestSection.live_website_test) {
    throw new Error("Live website test not found")
  }

  const tasks = usabilityTestSection.live_website_test.live_website_test_tasks

  const openTaskTab = (index?: number) => {
    const taskWindow = window.open(
      tasks[index ?? currentTaskIndex].url,
      "lwtWindow"
    )

    taskTab.current = taskWindow
  }

  const handleOpenPip = () => {
    if (isPipSupported) {
      openPip(400, 400)
    }
  }

  const handleStartTask = () => {
    handleStart()
    openTaskTab()
    setTimeout(updatePipStyles)
  }

  const handleEndTask = () => {
    taskTab.current?.close()
    closePip()
    handleFinish()
  }

  const handleSkip = async (reason: ParticipantDeletionReason) => {
    // TODO: This ought to move to OpenAPI at some point
    await axios.put(responsesApi.cancel.path({ id: responseId }), {
      deletion_reason: reason,
    })

    const dashboardPath = ROUTES.DASHBOARD.path

    window.location.href =
      reason === ParticipantDeletionReason.Skipped
        ? dashboardPath
        : testInterfaceApi.flagged.path()
  }

  const handleNextTask = () => {
    const newIndex = currentTaskIndex + 1

    if (newIndex < tasks.length) {
      openTaskTab(newIndex)
    }

    setCurrentTaskIndex(newIndex)
  }

  const url = new URL(tasks[0].url)

  return (
    <>
      <Center w="full" h="full">
        <Flex direction="column" maxW="600px" bg="white" rounded="lg" mt={10}>
          <Heading as="h3" mx={4} mt={4} mb={isStarted ? 0 : 4}>
            This study will be completed on {url.hostname}
          </Heading>

          <Flex bg="gray.100" rounded="md" p={4} mx={4} mt={2}>
            <Text>You must keep this tab open during the test.</Text>
          </Flex>

          <Flex m={4} align="center" gap={4}>
            {isStarted ? (
              <Button colorScheme="brand.primary" onClick={() => openTaskTab()}>
                Re-open study
              </Button>
            ) : (
              <Button
                colorScheme="brand.primary"
                onClick={isPipSupported ? handleOpenPip : handleStartTask}
              >
                {isPipSupported ? "Open instructions" : "Open study"}
              </Button>
            )}

            {isStarted && isPipSupported && !isPipOpen && (
              <Text>
                Or{" "}
                <Link display="inline" onClick={handleOpenPip}>
                  re-open the instructions window.
                </Link>
              </Text>
            )}
          </Flex>
        </Flex>
      </Center>

      {isPipOpen && (
        <DocumentPictureInPicture>
          {isStarted ? (
            <>
              <LiveWebsiteTestControlPanel
                usabilityTestSection={usabilityTestSection}
                currentTaskIndex={currentTaskIndex}
                handleNextTask={handleNextTask}
                onClose={handleEndTask}
                onReport={() => {
                  setShowFullPageReportForm(true)
                  setTimeout(updatePipStyles)
                }}
              />

              <FullPageSkipForm
                isExternal
                isOpen={showFullPageReportForm}
                onClose={() => setShowFullPageReportForm(false)}
                onSkip={handleSkip}
                language={language}
              />
            </>
          ) : (
            <Flex
              direction="column"
              // This might display before the styles have loaded so safest to declare them inline
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "16px",
                gap: "16px",
              }}
              h="full"
            >
              <Text>
                This window will remain open while you complete the live website
                test so that you have the instructions handy.
              </Text>

              <Button colorScheme="brand.primary" onClick={handleStartTask}>
                Open study
              </Button>
            </Flex>
          )}
        </DocumentPictureInPicture>
      )}

      {language && (
        <SkipUsabilityTestModal
          isExternal
          isOpen={isReportModalOpen}
          onClose={() => setIsReportModalOpen(false)}
          onSkip={handleSkip}
          language={language}
        />
      )}
    </>
  )
}

const FullPageSkipForm: React.FC<
  {
    isOpen: boolean
  } & ComponentProps<typeof SkipUsabilityTestForm>
> = ({ isOpen, ...skipFormProps }) => {
  return (
    <Flex
      direction="column"
      display={isOpen ? "flex" : "none"}
      position="absolute"
      top={0}
      left={0}
      h="full"
      bg="white"
      gap={2}
      p={4}
      overflowY="scroll"
    >
      <Heading as="h3">Are you sure you want to skip this test?</Heading>
      <Text fontSize="md" fontWeight="normal">
        Please select the reason you want to skip it.
      </Text>
      <Box mt="auto">
        <SkipUsabilityTestForm {...skipFormProps} />
      </Box>
    </Flex>
  )
}
