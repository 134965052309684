import { Flex, HStack, Icon, Radio, RadioGroup, Text } from "@chakra-ui/react"
import classNames from "classnames"
import { noop } from "lodash"
import React from "react"
import { connect } from "react-redux"

import { NewTabAnchor } from "Components/anchors/basic"
import { IconButton } from "Components/icon-button/icon-button"
import {
  REVIEW_STATUS_OPTIONS,
  REVIEW_STATUS_OPTIONS_THIRD_PARTY_ORDER,
} from "Components/order-responses-review/response-durations/response-durations"
import { useTestResultViewState } from "Components/test-results/context/test-result-view-state"
import { useIndividualResponseFilter } from "Components/test-results/hooks/use-individual-response-filter"
import {
  Answer,
  Props as AnswerProps,
} from "Components/test-results/question-results/answer/answer"
import styles from "Components/test-results/question-results/answer/answer.module.scss"
import { DisplayDate } from "Components/time/display-date"
import { PreciseDuration } from "Components/time/precise-duration"
import { CustomTooltip } from "Components/tooltip/CustomTooltip"
import { Dispatch, State } from "Redux/app-store"
import { setResponseReviewStatus } from "Redux/reducers/test-results/action-creators"
import { getResponse } from "Redux/reducers/test-results/selectors"
import { UserResponseIcon } from "Shared/icons/UserResponseIcon"
import { Trash01OutlineIcon } from "Shared/icons/untitled-ui/Trash01OutlineIcon"
import { Response, ResponseAnswer, ReviewStatus } from "Types"
import { getAdminDashboardUsersLink } from "Utilities/get-admin-dashboard-users-link"
import { isDeleted } from "Utilities/response"
import { stringPresence } from "Utilities/values"

interface ImplProps extends AnswerProps {
  response: Response
  onReviewChange: (isApproved: ReviewStatus | null) => void
}

const ReviewAnswerImpl: React.FC<React.PropsWithChildren<ImplProps>> = (
  props
) => {
  const { isThirdPartyOrder } = useTestResultViewState()

  const { answer, response, onReviewChange } = props
  const { setResponseId } = useIndividualResponseFilter()

  let highlightStyle
  if (response.review_status === ReviewStatus.Rejected) {
    highlightStyle = styles.rejectedAnswer
  } else if (response.review_status === ReviewStatus.AcceptedLowQuality) {
    highlightStyle = styles.acceptedLowQualityAnswer
  } else if (isDeleted(response)) {
    highlightStyle = styles.deletedAnswer
  } else if (response.review_status === ReviewStatus.Accepted) {
    highlightStyle = styles.acceptedAnswer
  }

  return (
    <div className={classNames(styles.reviewableAnswer, highlightStyle)}>
      <Answer
        answer={answer}
        isSelectable={false}
        isSelected={false}
        onDeselect={noop}
        onSelect={noop}
        question={{} as any} // cheaty
        showAnswerTagButton={false}
      />
      <div
        className={styles.responseReviewActionContainer}
        data-qa="response-review-actions"
      >
        <div className={styles.responseReviewInfo}>
          <IconButton
            icon={<UserResponseIcon />}
            onClick={() => setResponseId(response.id)}
          />
          {isThirdPartyOrder ? (
            <Text px={2.5}>{response.cint_respondent_id}</Text>
          ) : (
            <NewTabAnchor
              href={getAdminDashboardUsersLink(String(response.user_id))}
            >
              View tester
            </NewTabAnchor>
          )}

          <PreciseDuration ms={answer.duration_ms} />
          {isDeleted(response) && (
            <CustomTooltip
              tooltip={
                <Flex direction="column">
                  <Text>
                    Deleted @ <DisplayDate date={response.deleted_at!} />
                  </Text>
                  <Text>Reason: {response.deletion_reason}</Text>
                </Flex>
              }
              p={5}
            >
              <Icon as={Trash01OutlineIcon} ml={2.5} color="ds.icon.subtle" />
            </CustomTooltip>
          )}
        </div>
        <RadioGroup
          value={
            stringPresence(
              response.review_status || response.automated_review_status
            ) || undefined
          }
          onChange={(value) =>
            onReviewChange(value ? (parseInt(value, 10) as ReviewStatus) : null)
          }
        >
          <HStack spacing="4" marginInlineEnd="4">
            {(isThirdPartyOrder
              ? REVIEW_STATUS_OPTIONS_THIRD_PARTY_ORDER
              : REVIEW_STATUS_OPTIONS
            ).map(({ label, value }) => (
              <Radio key={value} value={String(value)}>
                {label}
              </Radio>
            ))}
          </HStack>
        </RadioGroup>
      </div>
    </div>
  )
}

interface OwnProps {
  readonly answer: Readonly<ResponseAnswer>
}

export const ReviewAnswer = connect(
  (state: State, ownProps: OwnProps) => ({
    response: getResponse(state, ownProps.answer.response_id),
  }),
  (dispatch: Dispatch, ownProps: OwnProps) => ({
    onReviewChange: (reviewStatus: ReviewStatus) => {
      dispatch(
        setResponseReviewStatus(ownProps.answer.response_id, reviewStatus)
      )
    },
  })
)(ReviewAnswerImpl)
