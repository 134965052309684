/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { Box, Button, Flex, Heading, Switch } from "@chakra-ui/react"
import React, { ChangeEvent, useEffect, useState } from "react"
import { Helmet } from "react-helmet"

import {
  FormScreenerQuestions,
  ScreenerForm,
} from "Shared/components/ScreenerForm/ScreenerForm"
import { ModeratedStudyDetailsForm } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/ModeratedStudyDetailsForm"
import { useAvailabilitySectionForm } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/forms/useAvailabilitySectionForm"
import { useDeviceRequirementForm } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/forms/useDeviceRequirementForm"
import { useMeetingUrlForm } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/forms/useMeetingUrlForm"
import { useStudyDetailsForm } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/forms/useStudyDetailsForm"
import { useTeamMembersForm } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/forms/useTeamMembersForm"
import { ModeratedStudyAvailabilityForm } from "./availability/ModeratedStudyAvailabilityForm"

import { SCREENER_QUESTION_TYPES } from "Shared/components/ScreenerForm/constants"
import { useModeratedStudyScreenerQuestions } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/screeners/useModeratedStudyScreenerQuestions"
import { ROUTES } from "UsabilityHub/views/routes"
import { Link } from "react-router-dom"
import { EmailPreviewSection } from "./EmailPreviewSection"
import { useModeratedStudyContext } from "./ModeratedStudyContext"
import { ModeratedStudySavingIndicator } from "./ModeratedStudySavingIndicator"
import { ModeratedStudySectionStatuses } from "./ModeratedStudySectionStatuses"
import { CalendarConnection } from "./availability/CalendarConnection"

export const ModeratedStudyEditPage: React.FC = () => {
  const { moderatedStudy, invalidateStudySummaryQuery } =
    useModeratedStudyContext()

  const moderatedStudyId = moderatedStudy.id

  // We've hoisted these form up so they can be passed to the sections that need them as well as the
  // saving indicator which needs to keep track of all form activity.
  const availabilityForm = useAvailabilitySectionForm(moderatedStudy)
  const studyDetailsForm = useStudyDetailsForm(moderatedStudy)
  const meetingUrlForm = useMeetingUrlForm(moderatedStudy)
  const deviceRequirementForm = useDeviceRequirementForm(moderatedStudy)
  const teamMembersForm = useTeamMembersForm(moderatedStudy)

  // We also need to track some individual mutations to affect the saving indicator
  // Once we need more of these it might pay to abstract this out
  const [isMutatingTeamMembers, setMutatingTeamMembers] = useState(false)
  const mutationState = [
    { formState: { isSubmitting: isMutatingTeamMembers, isDirty: false } },
  ]

  const [isScreenerEnabled, setIsScreenerEnabled] = useState<boolean>(
    moderatedStudy.screener_enabled
  )

  // Each screener question is a form in itself, so we're storing status information for each one in
  // an array so we can pass it to the status component and saving indicator.
  const [formScreenerQuestions, setFormScreenerQuestions] =
    useState<FormScreenerQuestions>([])

  const {
    screenerQuestions,
    appendScreenerQuestion,
    removeScreenerQuestion,
    updateScreenerQuestion,
    duplicateScreenerQuestion,
    onScreenerToggle,
  } = useModeratedStudyScreenerQuestions({
    moderatedStudyId,
    setIsScreenerEnabled,
    onDisable: () => setFormScreenerQuestions([]),
  })

  // The screener questions each have their own form, but the UI treats it as a single entry.
  // Loop over each question and combine the errors so they can be passed to the status component.
  const combinedScreenerQuestionErrors = {
    formState: {
      errors: formScreenerQuestions.reduce((errors, question) => {
        // Note this is a shallow merge, so if different questions have errors on the same field
        // some will be overridden. For now it doesn't matter but if we add more advanced error
        // messaging we might need to update this.
        return { ...errors, ...question.formState.errors }
      }, []),
      isScreenerEnabled,
    },
  }

  // After availability or team members are saved, we need to invalidate the study summary query
  const shouldUpdateSummary =
    availabilityForm.formState.isSubmitSuccessful ||
    teamMembersForm.formState.isSubmitSuccessful

  useEffect(() => {
    if (shouldUpdateSummary) void invalidateStudySummaryQuery()
  }, [shouldUpdateSummary])

  useEffect(() => {
    setIsScreenerEnabled(moderatedStudy.screener_enabled)
  }, [moderatedStudy.screener_enabled])

  const forms: { formState: { isDirty: boolean; isSubmitting: boolean } }[] = [
    studyDetailsForm,
    meetingUrlForm,
    deviceRequirementForm,
    availabilityForm,
    teamMembersForm,
    ...mutationState,
    ...formScreenerQuestions,
  ]
  const isAnyFormDirty = forms.some((f) => f.formState.isDirty)
  const isAnyFormSubmitting = forms.some((f) => f.formState.isSubmitting)

  return (
    <main>
      <Helmet>
        <title>Setup</title>
      </Helmet>
      <Box my="8" px="8" w="full" mx="auto">
        <Flex w="full" justify="space-between">
          <Box flexBasis="xs" flexShrink={0} me={4}>
            <Flex direction="column" position="sticky" top={20} gap={2}>
              <ModeratedStudySectionStatuses
                studyDetailsForm={studyDetailsForm}
                meetingUrlForm={meetingUrlForm}
                deviceRequirementForm={deviceRequirementForm}
                availabilityForm={availabilityForm}
                screenerQuestions={combinedScreenerQuestionErrors}
                teamMembersForm={teamMembersForm}
              />

              <Box my={2}>
                <ModeratedStudySavingIndicator
                  isAnyDirty={isAnyFormDirty}
                  isAnySubmitting={isAnyFormSubmitting}
                />
              </Box>

              <Button
                as={Link}
                target="_blank"
                colorScheme="gray"
                to={ROUTES.INTERVIEW.PREVIEW.APPLY.buildPath({
                  moderatedStudyId,
                })}
              >
                Preview
              </Button>

              <Button
                as={Link}
                colorScheme="brand.primary"
                to={ROUTES.INTERVIEW.RECRUIT.buildPath({
                  moderatedStudyId,
                })}
                isDisabled={isAnyFormDirty || isAnyFormSubmitting}
              >
                Next: Recruit
              </Button>
            </Flex>
          </Box>

          <Flex flexDir="column" gap="4" overflow="hidden" maxW="64rem">
            <Box
              id="study_details"
              bg="white"
              rounded="lg"
              p="8"
              borderColor="gray.200"
              borderWidth="thin"
            >
              <Heading fontSize="lg" fontWeight="semibold">
                Study details
              </Heading>

              <Box mt="6">
                <ModeratedStudyDetailsForm
                  studyDetailsForm={studyDetailsForm}
                  meetingUrlForm={meetingUrlForm}
                  deviceRequirementForm={deviceRequirementForm}
                  availabilityForm={availabilityForm}
                />
              </Box>
            </Box>

            <Box
              id="screener"
              bg="white"
              rounded="lg"
              borderColor="gray.200"
              borderWidth="thin"
              py="8"
            >
              <Flex justify="space-between" px={8}>
                <Heading fontSize="lg" fontWeight="semibold">
                  Screener
                </Heading>
                <Switch
                  isChecked={isScreenerEnabled}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    onScreenerToggle(event.target.checked)
                  }
                  data-qa="screener-toggle-switch"
                />
              </Flex>

              {isScreenerEnabled && screenerQuestions.length > 0 && (
                <Box mt={6}>
                  <ScreenerForm
                    screenerQuestions={screenerQuestions}
                    appendScreenerQuestion={appendScreenerQuestion}
                    removeScreenerQuestion={(id) => {
                      removeScreenerQuestion(id)
                      // After removing a question, we need to remove the associated form state too
                      setFormScreenerQuestions((oldQuestions) => {
                        return oldQuestions.filter((q) => q.questionId !== id)
                      })
                    }}
                    updateScreenerQuestion={updateScreenerQuestion}
                    duplicateScreenerQuestion={duplicateScreenerQuestion}
                    setFormScreenerQuestions={setFormScreenerQuestions}
                    renderFormElement
                    validQuestionTypes={[...SCREENER_QUESTION_TYPES]}
                    readOnly={false}
                  />
                </Box>
              )}
            </Box>

            <Box
              id="team_members"
              bg="white"
              rounded="lg"
              p="8"
              borderColor="gray.200"
              borderWidth="thin"
            >
              <CalendarConnection
                moderatedStudyId={moderatedStudyId}
                members={moderatedStudy.members}
                bookingCalendar={moderatedStudy.booking_calendar}
                teamMembersForm={teamMembersForm}
                setMutatingTeamMembers={setMutatingTeamMembers}
              />
            </Box>

            <Box
              id="availability"
              bg="white"
              rounded="lg"
              p="8"
              borderColor="gray.200"
              borderWidth="thin"
            >
              <ModeratedStudyAvailabilityForm
                availabilityForm={availabilityForm}
              />
            </Box>

            <Box
              id="automated_emails"
              bg="white"
              rounded="lg"
              p="8"
              borderColor="gray.200"
              borderWidth="thin"
            >
              <EmailPreviewSection />
            </Box>
          </Flex>
          <Box flexBasis="21rem" flexShrink={3} />
        </Flex>
      </Box>
    </main>
  )
}
